import { useEffect, useState } from 'react';
import {
  Modal, Button, Image, Row, Col, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import AlertIconImg from '../../../assets/images/alert-100.png';
import { productUpdate } from '../../../services/ProductService';

const ProductStatusAlertModal = ({ product, showSatusAlert, handleShowStatusAlert }) => {
  const [show, setShow] = useState(false);
  const [statusAlertTitle, setStatusAlertTitle] = useState('');
  const [statusAlertBadge, setStatusAlertBadge] = useState('');
  const [statusAlertStr, setSatusAlertStr] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusAlert = async () => {
    setIsLoading(true);
    try {
      const response = await productUpdate({ id: product.shopify_product_id, product_status: product.product_status });
      setIsLoading(false);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error(error.message);
    }
    handleShowStatusAlert();
  };

  useEffect(() => {
    setShow(showSatusAlert);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSatusAlert]);

  useEffect(() => {
    if (Number(product.product_status) === 1) {
      setStatusAlertTitle('Draft');
      setStatusAlertBadge('info');
      setSatusAlertStr('Setting products as Draft will make them not visible to customers in VR Store.');
    } else if (Number(product.product_status) === 2) {
      setStatusAlertTitle('Active');
      setStatusAlertBadge('success');
      setSatusAlertStr('Setting products as Active will make them visible to customers in VR Store.');
    } else if (Number(product.product_status) === 3) {
      setStatusAlertTitle('Archived');
      setStatusAlertBadge('danger');
      setSatusAlertStr('Setting products as Archived will make them not visible to customers in VR Store.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product.product_status]);

  return (
    <Modal className="maxWidth flexDirection" show={show} onHide={handleShowStatusAlert}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 modal-title h4">Set 1 product(s) as {statusAlertTitle}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="col-md-12 text-center">
            <Image src={AlertIconImg} alt='alert' width={100} />
          </Col>
          <Col className="col-md-12">
            <p className="mb-0 mt-2 fs-6">{statusAlertStr}</p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" type="button" variant="secondary" onClick={handleShowStatusAlert}>
          Cancel
        </Button>
        <Button type="button" variant={statusAlertBadge} onClick={handleStatusAlert} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Set as {statusAlertTitle}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ProductStatusAlertModal;
