import { useEffect, useState } from 'react';
import {
  Offcanvas, Form, Tabs, Tab, Row, Col, Image,
} from 'react-bootstrap';
import { marketList } from '../../../services/SolutionService';
import { getStoreSession } from '../../../services/StoreService';
import pickerIcon from '../../../assets/images/pickerIcon.png';

const SolutionSetting = ({ showSolutionSetting, handleShowSolutionSetting, selectedSolution }) => {
  const [show, setShow] = useState(false);
  const [solution, setSolution] = useState({});
  const [markets, setMarkets] = useState([]);
  const store = getStoreSession();

  const getMarkets = async () => {
    try {
      const response = await marketList(store.id);
      if (response.success) {
        setMarkets(response.data);
      } else {
        setMarkets([]);
      }
    } catch (error) {
      setMarkets([]);
    }
  };

  useEffect(() => {
    setShow(showSolutionSetting);
  }, [showSolutionSetting]);

  useEffect(() => {
    setSolution(selectedSolution);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSolution]);

  useEffect(() => {
    (async () => {
      await getMarkets();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Offcanvas className="settingCanvas w-50" show={show} onHide={handleShowSolutionSetting} placement="end">
      <Offcanvas.Header className="px-5" closeButton>
        <Offcanvas.Title>
          <div className="mb-0">Settings</div>
          <p className="mb-0 text13 fw-normal">{solution.name}</p>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body className="setingsTabs px-5">
        <Form>
          <Tabs
            defaultActiveKey="general"
            id="uncontrolled-tab-example"
            className="mb-4"
          >
            <Tab eventKey="general" title="General">
              <Row>
                <Col>
                  <h6 className="mb-3">Solution</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Name</Form.Label>
                    <Form.Control type="text" value={solution.name} onChange={(e) => setSolution({ ...solution, name: e.target.value })} />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Market</Form.Label>
                    <div className="position-relative w-40">
                      <Form.Select value={solution.market_id} onChange={(e) => setSolution({ ...solution, market_id: e.target.value })}>
                        {markets && markets.map((market) => <option key={market.id} value={market.id}>{market.name}</option>)}
                      </Form.Select>
                      <i className="bi bi-chevron-down"></i>
                    </div>
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Status</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.status === 'active'}
                      onChange={(e) => setSolution({ ...solution, status: e.target.checked })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="py-4">
                <Col>
                  <h6 className="mb-3">Supported Devices</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Web Browser</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.webbrowser}
                      onChange={(e) => setSolution({ ...solution, webbrowser: e.target.checked })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">VR Headset</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.vrheadset}
                      onChange={(e) => setSolution({ ...solution, vrheadset: e.target.checked })}
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <h6 className="mb-3">Loading Page</h6>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Show Loader Logo</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.showloaderlogo}
                      onChange={(e) => setSolution({ ...solution, showloaderlogo: e.target.checked })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Show User Help</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.showuserhelp}
                      onChange={(e) => setSolution({ ...solution, showuserhelp: e.target.checked })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Custom Logo</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.customlogo}
                      onChange={(e) => setSolution({ ...solution, customlogo: e.target.checked })}
                    />
                  </Form.Group>
                </Col>
                <Col xs={6}>
                  <Form.Group className="mb-2 d-flex align-items-center justify-content-end">
                    <Form.Label className="mb-0 colorLabel">Background Color</Form.Label>
                    <Image src={pickerIcon} alt="pickerIcon"/>
                    <Form.Control className="colorInput" type="color" value={solution.backgroundcolor} onChange={(e) => setSolution({ ...solution, backgroundcolor: e.target.value })} />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center justify-content-end">
                    <Form.Label className="mb-0 colorLabel">Text Color</Form.Label>
                    <Image src={pickerIcon} alt="pickerIcon"/>
                    <Form.Control className="colorInput" type="color" value={solution.textcolor} onChange={(e) => setSolution({ ...solution, textcolor: e.target.value })} />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="pt-4">
                <Col>
                  <h6 className="mb-3">Web Access</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">URL</Form.Label>
                    <Form.Control type="url" value={solution.store_url} onChange={(e) => setSolution({ ...solution, store_url: e.target.value })} />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Requires Password</Form.Label>
                    <Form.Check
                      type="switch"
                      defaultChecked={solution.requires_password}
                      onChange={(e) => setSolution({ ...solution, requires_password: e.target.checked })}
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Password</Form.Label>
                    <Form.Control type="password" value={solution.password} onChange={(e) => setSolution({ ...solution, password: e.target.value })} />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="multiplayer" title="Multiplayer">
              <Row>
                <Col xs={12}>
                  <h6 className="mb-3">General</h6>
                </Col>
                <Col className="mb_2" xs={12} md={4}>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Enable Multiplayer</Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Enable Friend Invites</Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={8}>
                  <Form.Group className="mb-2 d-flex align-items-center justify-content-end justify-start">
                    <Form.Label className="mb-0">Max Visable Users</Form.Label>
                    <Form.Control type="number" />
                  </Form.Group>
                </Col>
              </Row>
              <Row className="py-4">
                <Col>
                  <h6 className="mb-3">Chat</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Enable Voice Chat</Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Only Friends Chat</Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="mb-3">Store Assistant</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Enable Store Assist</Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
            <Tab eventKey="commerce" title="Commerce">
              <Row className="pb-4">
                <Col>
                  <h6 className="mb-3">Shopping Cart & Checkout</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Allow shopping </Form.Label>
                    <Form.Check
                      type="switch"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6 className="mb-3">Virtual Tablet</h6>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Homepage URL</Form.Label>
                    <Form.Control type="url" />
                  </Form.Group>
                  <Form.Group className="mb-2 d-flex align-items-center">
                    <Form.Label className="mb-0">Checkout URL</Form.Label>
                    <Form.Control type="url" />
                  </Form.Group>
                </Col>
              </Row>
            </Tab>
          </Tabs>
        </Form>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SolutionSetting;
