import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  InputGroup,
  Card,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import ecommerceAccount from '../../../assets/images/ecommerce-account.png';
import userIcon from '../../../assets/images/userIcon.png';
import phoneIcon from '../../../assets/images/phoneIcon.png';
// import emailIcon from '../../../assets/images/emailIcon.png';
import billingIcon from '../../../assets/images/billingIcon.png';
import { countryList, storeUpdate } from '../../../services/StoreService';

const schema = yup
  .object({
    first_name: yup.string().label('First name').required(),
    last_name: yup.string().label('Last name').required(),
    phone: yup.string().label('Phone').required(),
    email: yup.string().email().label('Email').required(),
    address_street: yup.string().label('Street and house number').required(),
    address_apartment: yup.string().label('Apartment, Suite, etc.').required(),
    address_postalcode: yup.string().label('Postal Code').required(),
    address_city: yup.string().label('City/Town').required(),
    country_name: yup.string().label('Country').required(),
  })
  .required();

const EditContactInformation = ({ store, handleContactInfo, getStoreDetail }) => {
  const [countries, setCountries] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getCountries = async () => {
    try {
      const response = await countryList();
      if (response.success) {
        setCountries(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStore = async (data) => {
    // eslint-disable-next-line
    console.log('clicked', data);
    const postData = {
      store_id: store.id,
      first_name: data.first_name,
      last_name: data.last_name,
      phone: data.phone,
      email: data.email,
      address_street: data.address_street,
      address_apartment: data.address_apartment,
      address_postalcode: data.address_postalcode,
      address_city: data.address_city,
      country_name: data.country_name,
    };
    try {
      const response = await storeUpdate(postData);
      if (response.success) {
        toast.success(response.message);
        await getStoreDetail();
        handleContactInfo();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  }, []);

  useEffect(() => {
    const fields = [
      'first_name',
      'last_name',
      'phone',
      'email',
      'address_street',
      'address_apartment',
      'address_postalcode',
      'address_city',
      'country_name',
    ];
    fields.forEach((field) => {
      if (field === 'country_name') {
        setValue(field, store.Country?.name);
      } else {
        setValue(field, store[field]);
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, countries]);

  return (
    <Card>
      <Form onSubmit={handleSubmit(handleStore)} noValidate>
        <div className="cardTitle">
          <Row className="align-items-center alignTop">
            <Col>
              <h3 className="fs-6 mb-1">Contact Information</h3>
              <p className="text-muted mb-0 text13">Used for VCOM billing and contacts about store and system information.</p>
            </Col>
            <Col className="text-end storeBtn">
              <Button type='button' onClick={handleContactInfo} className="cancel">Cancel</Button>
              <Button className="ms-3" variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </div>
        <div className="cardStore">
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={userIcon} alt="userIcon" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Name</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.first_name}
                        {...register('first_name')}
                        placeholder="First name"
                      />
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.last_name}
                        {...register('last_name')}
                        placeholder="Last name"
                      />
                      <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
                      <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
                    </InputGroup>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={phoneIcon} alt="phoneIcon" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Phone</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.phone}
                    {...register('phone')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={ecommerceAccount} alt="ecommerceAccount" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Email</Form.Label>
                  <Form.Control
                    type="email"
                    isInvalid={!!errors.email}
                    {...register('email')}
                  />
                  <Form.Text className="d-block" muted>VCOM uses this to contact you.</Form.Text>
                  <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row className="pt-3">
            <Col xs={12}>
              <div className="icon-with-text d-flex align-items-center gap12">
                <Image src={billingIcon} alt="billingIcon" />
                <div className="basicText">
                  <span className="text-muted text13">Billing Address</span>
                </div>
              </div>
            </Col>
            <Col xs={12}>
              <Form.Group className="mt-2 mb-3 w-100">
                <Form.Label className="mb-0 text-muted text13">Street and house number</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.address_street}
                  {...register('address_street')}
                />
                <Form.Control.Feedback type="invalid">{errors.address_street?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group className="mb-3 w-100">
                <Form.Label className="mb-0 text-muted text13">Apartment, Suite, etc.</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.address_apartment}
                  {...register('address_apartment')}
                />
                <Form.Control.Feedback type="invalid">{errors.address_apartment?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100">
                <Form.Label className="mb-0 text-muted text13">Postal Code</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.address_postalcode}
                  {...register('address_postalcode')}
                />
                <Form.Control.Feedback type="invalid">{errors.address_postalcode?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100">
                <Form.Label className="mb-0 text-muted text13">City/Town</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.address_city}
                  {...register('address_city')}
                />
                <Form.Control.Feedback type="invalid">{errors.address_city?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={6}>
              <Form.Group className="mb-3 w-100">
                <Form.Label className="mb-0 text-muted text13">Country</Form.Label>
                <Form.Select
                  isInvalid={!!errors.country_name}
                  {...register('country_name')}
                >
                  <option value="">Choose one</option>
                  {countries && countries.map((country) => <option key={country.id} value={country.name}>{country.name}</option>)}
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.country_name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  );
};

export default EditContactInformation;
