import { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import storeTypeImg from '../../../assets/images/storeType.png';
import backgroundImage from '../../../assets/images/backgroundImage.png';
import backgroundVideo from '../../../assets/images/backgroundVideo.png';
import storeDescription from '../../../assets/images/storeDescription.png';
import {
  storeImageUpload, storeVideoUpload, storeTypeList, storeUpdate,
} from '../../../services/StoreService';

const schema = yup
  .object({
    store_type: yup.string().label('Store type').required(),
    store_description: yup.string().label('Industry').optional(),
  })
  .required();

const EditMarketSetting = ({ store, handleMarketSetting, getStoreDetail }) => {
  const [storeTypes, setStoreTypes] = useState([]);
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getStoreTypes = async () => {
    try {
      const response = await storeTypeList();
      if (response.success) {
        setStoreTypes(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStore = async (data) => {
    const postData = {
      store_id: store.id,
      store_type: data.store_type,
      store_description: data.store_description,
    };
    try {
      const response = await storeUpdate(postData);
      if (response.success) {
        toast.success(response.message);
        await getStoreDetail();
        handleMarketSetting();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStoreImageUpload = async (selectedFiles) => {
    try {
      const formData = new FormData();
      formData.append('storeimage', selectedFiles[0]);
      const response = await storeImageUpload(formData, store.id);
      if (response.success) {
        toast.success(response.message);
        await getStoreDetail();
        handleMarketSetting();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleStoreVideoUpload = async (selectedFiles) => {
    try {
      const formData = new FormData();
      formData.append('storevideo', selectedFiles[0]);
      const response = await storeVideoUpload(formData, store.id);
      if (response.success) {
        toast.success(response.message);
        await getStoreDetail();
        handleMarketSetting();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getStoreTypes();
    })();
  }, []);

  useEffect(() => {
    if (storeTypes) {
      const fields = [
        'store_type',
        'store_description',
      ];
      fields.forEach((field) => {
        setValue(field, store[field]);
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store, storeTypes]);

  return (
    <Card className="my-4">
      <Form onSubmit={handleSubmit(handleStore)} noValidate>
        <div className="cardTitle">
          <Row className="align-items-center alignTop">
            <Col>
              <h3 className="fs-6 mb-1">VCOM Market Settings</h3>
              <p className="text-muted mb-0 text13">Change how your store get presented to customer on the VCOM Market.</p>
            </Col>
            <Col className="text-end storeBtn">
              <Button type='button' onClick={handleMarketSetting} className="cancel">Cancel</Button>
              <Button className="ms-3" variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </div>
        <div className="cardStore">
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={storeTypeImg} alt="Storename" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Store Type</Form.Label>
                  <Form.Select
                    isInvalid={!!errors.store_type}
                    {...register('store_type')}
                    className="form-control"
                  >
                    <option value="">Choose one</option>
                    {storeTypes && storeTypes.map((storeType) => <option key={storeType.id} value={storeType.id}>{storeType.name}</option>)}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.store_type?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={backgroundImage} alt="backgroundImage" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Background Image</Form.Label>
                  <Form.Control type="file" accept="image/*" onChange={(e) => handleStoreImageUpload(e.target.files)} />
                </Form.Group>
              </div>
            </Col>
          </Row>
          <Row className="pt-3 row">
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={backgroundVideo} alt="backgroundVideo" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Background Video</Form.Label>
                  <Form.Control type="file" accept="video/mp4,video/x-m4v,video/*" onChange={(e) => handleStoreVideoUpload(e.target.files)} />
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={storeDescription} alt="storeDescription" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Store Presentation</Form.Label>
                  <Form.Control
                    as="textarea"
                    isInvalid={!!errors.store_description}
                    {...register('store_description')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.store_description?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  );
};

export default EditMarketSetting;
