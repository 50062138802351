import { useEffect, useState } from 'react';
import {
  Button, Modal, Row, Col, Form, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { categoryUpdate } from '../../../services/ProductService';

const schema = yup
  .object({
    name: yup.string().label('Name').required(),
    short_description: yup.string().label('Short Description').required(),
    status: yup.boolean().label('Status').required(),
  })
  .required();

const EditCategoryModal = ({
  selectedCategory, getCategories, showEditCategoryModal, handleShowEditCategoryModal,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleEditCategoryModal = async (data) => {
    setIsLoading(true);
    try {
      const response = await categoryUpdate({
        id: selectedCategory.id,
        name: data.name,
        short_description: data.short_description,
        status: data.status,
      });
      setIsLoading(false);
      if (response.success) {
        handleShowEditCategoryModal();
        await getCategories();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setShow(showEditCategoryModal);
  }, [showEditCategoryModal]);

  useEffect(() => {
    setValue('name', selectedCategory.name);
    setValue('short_description', selectedCategory.short_description);
    setValue('status', selectedCategory.status);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCategory]);

  return (
    <Modal className="maxWidth" show={show} onHide={handleShowEditCategoryModal}>
      <Form onSubmit={handleSubmit(handleEditCategoryModal)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5 modal-title h4">Edit {selectedCategory.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.name}
                  {...register('name')}
                />
                <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Short Description</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.short_description}
                  {...register('short_description')}
                />
                <Form.Control.Feedback type="invalid">{errors.short_description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12} md={12}>
              <Form.Group className="mb-3">
                <Form.Label>Status</Form.Label>
                <Form.Select
                  isInvalid={!!errors.status}
                  {...register('status')}
                >
                  <option value={true}>Active</option>
                  <option value={false}>Inactive</option>
                </Form.Select>
                <Form.Control.Feedback type="invalid">{errors.status?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" type="button" variant="secondary" onClick={handleShowEditCategoryModal}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Save</span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default EditCategoryModal;
