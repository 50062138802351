import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { shopifyProductCreate } from '../../../services/ProductService';
import { getStoreSession } from '../../../services/StoreService';

const ImportProductModal = ({
  isSelectAllNotSyncProduct, selectedRows, showImportProductModal, handleShowImportProductModal, handleToggleCleared,
  getProducts,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const store = getStoreSession();

  const handleImportProductModal = async () => {
    setIsLoading(true);
    try {
      const response = await shopifyProductCreate({
        store_id: store.id,
        selected_ids: selectedRows,
        is_select_all_product: isSelectAllNotSyncProduct,
      });
      setIsLoading(false);
      if (response.success) {
        handleShowImportProductModal();
        handleToggleCleared();
        toast.success(response.message);
        await getProducts();
      } else {
        handleShowImportProductModal();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowImportProductModal();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setShow(showImportProductModal);
  }, [showImportProductModal]);

  return (
    <Modal show={show} onHide={handleShowImportProductModal}>
      <Modal.Header closeButton>
        <Modal.Title>Import ({selectedRows.length}) selected products?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This action will import selected products and make them available in your product view.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleShowImportProductModal}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleImportProductModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Import</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ImportProductModal;
