import { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Image,
  Card,
  Button,
} from 'react-bootstrap';
import Storename from '../../../assets/images/storename.png';
import Industry from '../../../assets/images/industry.png';
import ecommerceAccount from '../../../assets/images/ecommerce-account.png';

const BasicInformation = ({ store, handleBasicInfo }) => {
  const [storeData, setStoreData] = useState({});

  useEffect(() => {
    setStoreData(store);
  }, [store]);

  return (
    <Card>
      <div className="cardTitle">
        <Row>
          <Col><h3 className="fs-6 mb-0">Basic Information</h3></Col>
          <Col className="text-end"><Button className="edit btn-link p-0 bg-transparent border-0" onClick={handleBasicInfo}>Edit</Button></Col>
        </Row>
      </div>
      <div className="cardStore">
        <Row>
          <Col xs={6}>
            <div className="icon-with-text d-flex align-items-center gap12">
              <Image src={Storename} alt="Storename" />
              <div className="basicText">
                <span className="text-muted text13">Store Name</span>
                <h6>{storeData.store_name || '-'}</h6>
              </div>
            </div>
          </Col>
          <Col xs={6}>
            <div className="icon-with-text d-flex align-items-center gap12">
              <Image src={Industry} alt="Storename" />
              <div className="basicText">
                <span className="text-muted text13">Industry</span>
                <h6>{storeData.industry || '-'}</h6>
              </div>
            </div>
          </Col>
        </Row>
        <Row className="pt-3">
          <Col md={12}>
            <div className="icon-with-text d-flex align-items-center gap12">
              <Image src={ecommerceAccount} alt="ecommerceAccount" />
              <div className="basicText">
                <span className="text-muted text13">Connected E-Commerce Account</span>
                <h6>{storeData.shopify_store_email || '-'}</h6>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </Card>
  );
};

export default BasicInformation;
