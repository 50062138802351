import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Form } from 'react-bootstrap';
import ConfirmPublish from './ConfirmPublish';
import ConfirmPreview from './ConfirmPreview';

const PublishVRStore = ({ store, getStoreDetail }) => {
  const [vrStoreStatus, setVRStoreStatus] = useState(store.vr_store_status);
  const [showConfirmPublish, setShowConfirmPublish] = useState(false);
  const [showConfirmPreview, setShowConfirmPreview] = useState(false);

  const handleShowConfirmPreview = () => {
    setShowConfirmPreview(!showConfirmPreview);
  };

  const handleShowConfirmPublish = () => {
    setShowConfirmPublish(!showConfirmPublish);
  };

  const handleVRStoreStatus = (data) => {
    setVRStoreStatus(Number(data));
    if (Number(data) === 1) {
      handleShowConfirmPreview();
    } else {
      handleShowConfirmPublish();
    }
  };

  const handleVRStoreStatusDemo = (data) => {
    if (Number(data) === 1) {
      toast.error('Please subscribe a plan to preview the VR Store.');
    } else {
      toast.error('Please subscribe a plan to publish the VR Store.');
    }
  };

  useEffect(() => {
    setVRStoreStatus(store?.vr_store_status);
  }, [store]);

  return (
    <>
      <div className="d-flex align-items-start">
        <div className="vrStore">
          <h3 className="text17 mb-2">Make your VR-store visible for customers</h3>
          <p className="mb-5 text-muted">Publish your VR-store to make your products available at the VCOM Market. If you want to have a look at your store first, set your store in preview mode and sign in on the VCOM Market as store owner.</p>
          <Form noValidate>
            <div className="mb-3">
              <Form.Check type="radio">
                <Form.Group className="mb-4">
                  <div className="d-flex">
                    <div>
                      {(store.Subscription?.subscription_reason && store.Subscription?.subscription_reason !== 2
                      && store.Subscription?.subscription_reason !== 5 && store.Subscription?.subscription_reason !== 6
                      && store.Subscription?.subscription_reason !== 8 && store.Subscription?.subscription_reason !== 9) ? (<Form.Check.Input type="radio" name="vr_store_status" id="vr_store_preview" value={1} onChange={(e) => handleVRStoreStatus(e.target.value)} defaultChecked={store?.vr_store_status === 1} />) : (<Form.Check.Input type="radio" name="vr_store_status_demo" id="vr_store_preview" value={1} onChange={(e) => handleVRStoreStatusDemo(e.target.value)} defaultChecked={store?.vr_store_status === 1} />)}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="49" fill="#478bc8"></circle>
                        <circle cx="50" cy="50" r="43.5" fill="#ffffff"></circle>
                        <circle cx="50" cy="50" r="21.375" fill="#000000" className="unchk"></circle>
                        <circle cx="50" cy="50" r="14" fill="#17181a" className="unchk"></circle>
                      </svg>
                    </div>
                    <div>
                      <Form.Check.Label className="d-block text17" id="vr_store_preview">
                        Preview Mode
                      </Form.Check.Label>
                      <Form.Text id="passwordHelpBlock" muted>
                        VR-store will only be accessable by you and not visable for others.
                        Recommended when verifying settings and store content before going live.
                      </Form.Text>
                    </div>
                  </div>
                </Form.Group>
                <Form.Group className="mb-5">
                  <div className="d-flex align-items-top imagecheckbox">
                    <div>
                      {(store.Subscription?.subscription_reason && store.Subscription?.subscription_reason === 3) ? (<Form.Check.Input type="radio" name="vr_store_status" id="vr_store_published" value={2} onChange={(e) => handleVRStoreStatus(e.target.value)} defaultChecked={store?.vr_store_status === 2} />) : (<Form.Check.Input type="radio" name="vr_store_status_demo" id="vr_store_published" value={2} onChange={(e) => handleVRStoreStatusDemo(e.target.value)} defaultChecked={store?.vr_store_status === 2} />)}
                      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100">
                        <circle cx="50" cy="50" r="49" fill="#478bc8"></circle>
                        <circle cx="50" cy="50" r="43.5" fill="#ffffff"></circle>
                        <circle cx="50" cy="50" r="21.375" fill="#000000" className="unchk"></circle>
                        <circle cx="50" cy="50" r="14" fill="#17181a" className="unchk"></circle>
                      </svg>
                    </div>
                    <div>
                      <Form.Check.Label className="d-block text17" id='vr_store_published'>
                        Published
                      </Form.Check.Label>
                      <Form.Text id="passwordHelpBlock" muted>
                        Go live and make your VR-store available for your customers.
                      </Form.Text>
                    </div>
                  </div>
                </Form.Group>
              </Form.Check>
            </div>
          </Form>
          <div>
            <Link className="infoColor text-decoration-none" to={'https://vcomverse.io/docs/preview-and-publish-vr-store'} target="_blank" rel="noopener noreferrer"><i className="bi bi-question-circle-fill me-1"></i>Learn more about preview and publish VR Store<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
          </div>
        </div>
        <div className="col-md-3"></div>
      </div>
      <ConfirmPublish
        vrStoreStatus={vrStoreStatus}
        showConfirmPublish={showConfirmPublish}
        handleShowConfirmPublish={handleShowConfirmPublish}
        getStoreDetail={getStoreDetail}
      />
      <ConfirmPreview
        vrStoreStatus={vrStoreStatus}
        showConfirmPreview={showConfirmPreview}
        handleShowConfirmPreview={handleShowConfirmPreview}
        getStoreDetail={getStoreDetail}
      />
    </>
  );
};

export default PublishVRStore;
