import { useNavigate, NavLink, Link } from 'react-router-dom';
import {
  Alert, Image,
} from 'react-bootstrap';

import './merchant-navbar.css';
import useAuth from '../../hooks/useAuth';
import { logout } from '../../services/AuthService';
import { getStoreSession } from '../../services/StoreService';
import virturalStoreLogo from '../../assets/images/virturalStoreLogo.png';
import AlertImg from '../../assets/images/alert.svg';

function MerchantNavbar() {
  const navigate = useNavigate();
  const auth = useAuth();
  const user = auth.getSession();
  const store = getStoreSession();

  const handleLogout = async (e) => {
    e.preventDefault();

    await logout();
    navigate('/');
  };

  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  const getFirstLetter = (string) => string.charAt(0).toUpperCase();

  return (
    <nav className="navbar navbar-expand-lg navbar-dark sticky-top py-0 shadow">
      <div className="row align-items-center">
        <div className="col-md-2 col">
          <Link className="navbar-brand d-flex p-0" to={'/merchant'}>
            <Image src={virturalStoreLogo} alt="logo" />
          </Link>
        </div>
        <div className="col-md-4 menu ps-0 col">
          <ul className="nav menu flex-nowrap">
            <li className="nav-item">
              <NavLink className="nav-link" to={'/merchant'}>
                Commerce
              </NavLink>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to={'/virtual/solution'}>
                Virtual Store
              </NavLink>
            </li>
          </ul>
        </div>
        <div className="col-md-3 col-6 desktopView">
            <h3 className="merchant-heading">Merchant Admin Portal</h3>
        </div>
        <div className="col-md-3 col-6">
          <div className="mobileNav">
            <div className="text-end togglerBtn">
              <button
                className="navbar-toggler collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target="#sidebarMenu"
                aria-controls="sidebarMenu"
                aria-expanded="false"
                aria-label="Toggle navigation"
              >
                <i className="bi bi-list"></i>
                <i className="bi bi-x-lg"></i>
              </button>
            </div>
            <div className="userDropdown" id="navbarDropdown">
              <ul className="navbar-nav ms-auto flex-nowrap">
                <li className="nav-item dropdown">
                  <button className="nav-link dropdown-toggle nav-avatar-dropdown p-0"
                    id="dropdownMenu"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <span className="userIcon">{getFirstLetter(user.first_name)}{getFirstLetter(user.last_name)}</span>
                    {capitalizeFirstLetter(user.first_name)} {capitalizeFirstLetter(user.last_name)}
                  </button>
                  <ul className="dropdown-menu dropdown-menu-end" aria-labelledby="dropdownMenu">
                    <li>
                      <a className="dropdown-item" href='/merchant/profile'><i className="bi bi-person-fill me-2"></i>My Account</a>
                    </li>
                    <li>
                      <button className="dropdown-item" onClick={handleLogout}><i className="bi bi-box-arrow-left me-2"></i>Logout</button>
                    </li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-6 mobileView">
            <h3 className="merchant-heading">Merchant Admin Portal</h3>
        </div>
      </div>
      {store.Subscription
      && (store.Subscription?.subscription_reason === 2
        || store.Subscription?.subscription_reason === 5
        || store.Subscription?.subscription_reason === 6
        || store.Subscription?.subscription_reason === 8
        || store.Subscription?.subscription_reason === 9
      ) && (
        <div className="storeDeactivated text-center">
          <Alert className="col-md-12 d-inline-flex justify-content-center mb-0" variant="success">
            <Image src={AlertImg} width="20" height="20" alt="alert" />&nbsp;
            {store.Subscription?.subscription_reason === 2 && (
              <span>Free Trial has ended. <Link to={'/merchant/subscription/plan/list'}>Click here</Link> and take action to reveal any restrictions.</span>
            )}
            {(store.Subscription?.subscription_reason === 5
            || store.Subscription?.subscription_reason === 6
            || store.Subscription?.subscription_reason === 8) && (
              <span>Store is deactivated. <Link to={'/merchant/subscription/plan/list'}>Click here</Link> and take action to reveal any restrictions.</span>
            )}
            {store.Subscription?.subscription_reason === 9 && (
              <span>Store is archived due to inactivity and will be deleted in xx days. <Link to={'/merchant/subscription/plan/list'}>Click here</Link> to activate store.</span>
            )}
          </Alert>
        </div>
      )}
    </nav>
  );
}

export default MerchantNavbar;
