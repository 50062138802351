import { useState } from 'react';
import { Helmet } from 'react-helmet';
import { useNavigate, Link, useLocation } from 'react-router-dom';
import {
  Alert, Button, Form, FormControl, FormLabel, Spinner, Image,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './login.css';
import useAuth from '../../hooks/useAuth';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    company_name: yup.string().label('Organization name').required(),
    first_name: yup.string().label('First name').required(),
    last_name: yup.string().label('Last name').required(),
    email: yup.string().email().label('Email').required(),
    password: yup.string().label('Password').required()
      .matches(
        /^.*(?=.{6,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/,
        'The password you entered does not meet our password criteria. Please enter another.',
      ),
    c_password: yup.string().required('Please confirm your password.').oneOf([yup.ref('password'), null], 'Your passwords do not match.'),
    tcond: yup.bool().label('Terms and Condition')
      .oneOf([true]).required('You must accept the terms and conditions'),
  })
  .required();

const Signup = () => {
  const title = 'Signup';
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const [message, setMessage] = useState(location.state?.message || '');
  const { addUser } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleSignup = async (data) => {
    try {
      setIsLoading(true);
      const response = await addUser(data);
      if (response.success) {
        setIsLoading(false);
        navigate('/login', { state: { successMessage: response.message } });
      } else {
        setIsLoading(false);
        setMessage(response.message);
      }
    } catch (err) {
      setIsLoading(false);
      setMessage(err.message);
    }
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 bgLight px-3">
        <div className="loginCard mt-4">
          <Form className="g-2" onSubmit={handleSubmit(handleSignup)} noValidate>
            <div className="text-center">
              <Link className="logo mb-3" to={'/merchant'}>
                <Image src={logo} alt="logo" />
              </Link>
              <h3>Create your VCOM account</h3>
              {message && <Alert variant="danger">{message}</Alert>}
            </div>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Organization Name</FormLabel>
              <FormControl
                type="text"
                {...register('company_name')}
                isInvalid={!!errors.company_name}
                placeholder="Enter organization name"
              />
              <Form.Control.Feedback type="invalid">{errors.company_name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3">
              <FormLabel>First Name</FormLabel>
              <FormControl
                type="text"
                {...register('first_name')}
                isInvalid={!!errors.first_name}
                placeholder="Enter first name"
              />
              <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Last Name</FormLabel>
              <FormControl
                type="text"
                {...register('last_name')}
                isInvalid={!!errors.last_name}
                placeholder="Enter last name"
              />
              <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3" controlId="inputEmail">
              <FormLabel>Email</FormLabel>
              <FormControl
                type="email"
                {...register('email')}
                isInvalid={!!errors.email}
                placeholder="Enter email"
              />
              <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Password</FormLabel>
              <FormControl
                type="password"
                {...register('password')}
                isInvalid={!!errors.password}
                placeholder="Enter password"
              />
              <Form.Control.Feedback type="invalid">{errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="form-floating mb-3">
              <FormLabel>Confirm Password</FormLabel>
              <FormControl
                type="password"
                {...register('c_password')}
                isInvalid={!!errors.c_password}
                placeholder="Enter confirm password"
              />
              <Form.Control.Feedback type="invalid">{errors.c_password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="my-3">
              <Form.Check
                type="checkbox"
                label="By creating an account, you agree to our Terms of Service and have read and understood the Privacy Policy."
                className="text-muted creating-account"
                {...register('tcond')}
                isInvalid={!!errors.tcond}
                value={true}
              />
              <Form.Control.Feedback type="invalid">{errors.tcond?.message}</Form.Control.Feedback>
            </Form.Group>
            <Button
              className="w-100 btn btn-lg btn-primary"
              type="submit"
              disabled={isLoading}
            >
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">Register</span>
            </Button>
            <p className="mt-3 mb-0 text-center">Already registered? <Link className="blueColor mx-0" to={'/login'}>Login here.</Link></p>
          </Form>
        </div>
      </main>
    </>
  );
};

export default Signup;
