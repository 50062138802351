import { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { solutionDelete } from '../../../services/SolutionService';

const SolutionDeleteModal = ({
  showSolutionDelete,
  handleShowSolutionDelete,
  selectedSolution,
  getSolutions,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSolutionDelete = async () => {
    setIsLoading(true);
    try {
      const response = await solutionDelete({ id: selectedSolution.id });
      if (response.success) {
        setIsLoading(false);
        toast.success('Solution has been removed.');
        await getSolutions();
        handleShowSolutionDelete();
      } else {
        setIsLoading(false);
        handleShowSolutionDelete();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowSolutionDelete();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setShow(showSolutionDelete);
  }, [showSolutionDelete]);

  return (
    <>
      <Modal show={show} onHide={handleShowSolutionDelete}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Delete Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure that you want to delete this solution and all of it's customizations?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowSolutionDelete}>
            No
          </Button>
          <Button variant="primary" onClick={handleSolutionDelete} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SolutionDeleteModal;
