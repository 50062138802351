import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import {
  Card, Row, Col, Image, Button,
} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

import './subscriptiondetail.css';
import visaIcon from '../../../assets/images/cards/visa-svgrepo-com.svg';
import masterIcon from '../../../assets/images/cards/mastercard-svgrepo-com.svg';
import discoverIcon from '../../../assets/images/cards/discover-svgrepo-com.svg';
import amexIcon from '../../../assets/images/cards/amex-3-svgrepo-com.svg';
import dinersIcon from '../../../assets/images/cards/diners-3-svgrepo-com.svg';
import jcbIcon from '../../../assets/images/cards/jcb-svgrepo-com.svg';
import unionpayIcon from '../../../assets/images/cards/unionpay-2-svgrepo-com.svg';
import { getStoreSession, storeDetail } from '../../../services/StoreService';
import {
  cardList, chargeDetail, getChargeSession, setChargeSession,
} from '../../../services/BillingService';
import useAuth from '../../../hooks/useAuth';
import DeactivateSubscription from '../../../components/merchant/subscription/DeactivateSubscription';
import ChangePaymentMethod from '../../../components/merchant/subscription/ChangePaymentMethod';

function SubscriptionDetail() {
  const title = 'VCOM Merchant: Subscription';
  const [charge, setCharge] = useState({});
  const [cards, setCards] = useState([]);
  const [defaultCard, setDefaultCard] = useState({});
  const [showDeactivateStore, setShowDeactivateStore] = useState(false);
  const [showChangePaymentMethod, setShowChangePaymentMethod] = useState(false);
  const { getSession } = useAuth();
  const navigate = useNavigate();

  const handleShowDeactivateStore = () => {
    setShowDeactivateStore(!showDeactivateStore);
  };

  const handleShowChangePaymentMethod = () => {
    setShowChangePaymentMethod(!showChangePaymentMethod);
  };

  const updateChargeSession = async () => {
    const storeData = getStoreSession();
    if (storeData) {
      try {
        const response = await storeDetail(storeData.id);
        if (response.success && response.data && response.data.subscription_id) {
          setChargeSession(response.data.subscription_id);
        } else {
          setChargeSession('');
        }
      } catch (error) {
        setChargeSession('');
      }
    }
  };

  const getCharge = async () => {
    const chargeId = getChargeSession();
    if (!chargeId) {
      navigate('/merchant/subscription/plan/list');
    } else {
      try {
        const response = await chargeDetail(chargeId);
        if (response.success) {
          const chargeResponse = response.data;
          setCharge(chargeResponse);
        } else {
          navigate('/merchant/subscription/plan/list');
        }
      } catch (error) {
        navigate('/merchant/subscription/plan/list');
      }
    }
  };

  const getCards = async () => {
    const user = getSession();
    if (user && user.tenant_id) {
      const tenantId = user.tenant_id;
      try {
        const response = await cardList(tenantId);
        if (response.success) {
          const cardResponse = response.data;
          setCards(cardResponse);
        }
      } catch (error) {
        setCards([]);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await updateChargeSession();
      await getCards();
      await getCharge();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cards && cards.length > 0) {
      const cardId = charge.Charge?.card_id;
      const result = cards.filter((card) => card.id === cardId);
      setDefaultCard(result[0]);
    }
  }, [cards, charge]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="subscription-detail minHeight">
        <div className="px-3 mx_0 m_0 mx_3 mx-5 pt-5">
          <div className="mb-4">
            <h2 className="h5 pb-3 mb-3 border-bottom">{title}</h2>
            <p className="text-muted mb-0">You can manage or change your subscription at any time. Read more about our
              <Link className="infoColor" to={'https://vcomverse.io/docs/terms-of-service-privacy-policy/'} target='_blank'> terms of service</Link> and  <Link className="infoColor" to={'https://vcomverse.io/docs/terms-of-service-privacy-policy/'} target='_blank'>privacy policy</Link>.
            </p>
          </div>
          <Card className="subscriptionPlan">
            <Card.Header className="p-4 bg-transparent">
              <Row className="align-items-center vcmonthly-subscription">
                <Col md={9}>
                  <Card.Title>{charge.Plan?.name}</Card.Title>
                  <Card.Text className="text-muted">
                    Subscription is automatically renewed along with payment withdraw from registered credit card.
                    You can cancel the subscription at any time.
                  </Card.Text>
                </Col>
                <Col md={3} className="text-end">
                  <span className="text-muted">{charge.Plan?.currency}</span>
                  <span className="fs-3 px-1">{charge.Plan?.price}</span>
                  <span className="text-muted">/ {charge.Plan?.interval}</span>
                </Col>
              </Row>
            </Card.Header>
            <Card.Body className="p-4">
              <Row className="align-items-center">
                <Col md={6}>
                  <h6 className="text12 mb-0">PAYMENT METHOD</h6>
                </Col>
                <Col md={6} className="text-end">
                  <Button onClick={handleShowChangePaymentMethod} className="link" variant="link">Change payment method</Button>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <div className="d-flex align-items-center">
                    {defaultCard?.brand === 'visa' && <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />}
                    {defaultCard?.brand === 'mastercard' && <Image className="paymentIcon" src={masterIcon} alt="masterIcon" />}
                    {defaultCard?.brand === 'discover' && <Image className="paymentIcon" src={discoverIcon} alt="discoverIcon" />}
                    {defaultCard?.brand === 'amex' && <Image className="paymentIcon" src={amexIcon} alt="amexIcon" />}
                    {(defaultCard?.brand === 'dinersclub' || defaultCard?.brand === 'diners') && <Image className="paymentIcon" src={dinersIcon} alt="dinersIcon" />}
                    {defaultCard?.brand === 'jcb' && <Image className="paymentIcon" src={jcbIcon} alt="jcbIcon" />}
                    {defaultCard?.brand === 'unionpay' && <Image className="paymentIcon" src={unionpayIcon} alt="unionpayIcon" />}
                    <p className="mb-0 ms-3">{defaultCard?.name}<br />Ending in {`************${defaultCard?.card_no || ''}`}</p>
                  </div>
                </Col>
              </Row>
            </Card.Body>
            <Card.Footer className="p-4 bg-transparent text-end">
              <Button type='button' variant="outline-danger" onClick={handleShowDeactivateStore}>Deactivate Store </Button>
              <Link to={'/merchant/subscription/plan/list'} className="ms-2 btn btn-primary">Change Plan</Link>
            </Card.Footer>
          </Card>
        </div>
      </div>
      <ToastContainer />
      <DeactivateSubscription
        chargeId={charge.id}
        showDeactivateStore={showDeactivateStore}
        handleShowDeactivateStore={handleShowDeactivateStore}
      />
      <ChangePaymentMethod
        chargeId={charge.id}
        cards={cards}
        showChangePaymentMethod={showChangePaymentMethod}
        handleShowChangePaymentMethod={handleShowChangePaymentMethod}
        getCharge={getCharge}
      />
    </>
  );
}

export default SubscriptionDetail;
