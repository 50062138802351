import { useEffect } from 'react';
import {
  Card, Button, Row, Col,
  Form,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';
import { tenantUpdate } from '../../services/UserService';

const schema = yup
  .object({
    first_name: yup.string().label('First name').required(),
    last_name: yup.string().label('Last name').required(),
    phone: yup.string().label('Phone no.').required(),
    email: yup.string().label('Email').required(),
  })
  .required();

const ProfileEdit = ({ tenant, handleIsEdit, getTenant }) => {
  const title = 'Edit Profile';

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleEditProfile = async (data) => {
    try {
      const response = await tenantUpdate({
        id: tenant.id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
      });
      if (response.success) {
        toast.success(response.message);
        await getTenant();
        handleIsEdit();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setValue('first_name', tenant.first_name);
    setValue('last_name', tenant.last_name);
    setValue('email', tenant.email);
    setValue('phone', tenant.phone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  return (
    <>
      <div className="profilePage minHeight">
        <Form onSubmit={handleSubmit(handleEditProfile)} noValidate>
          <div className="px-3 mx_0 mx-5 pt-5">
            <div className="d-flex justify-content-between align-items-center">
              <h2 className="title mb-0">{title} ({tenant.first_name} {tenant.last_name})</h2>
              <div>
                <Button type="button" className="cancel me-2" onClick={() => handleIsEdit()}>Cancel</Button>
                <Button type="submit">Save</Button>
              </div>
            </div>
            <Card className="d-flex mt-3">
              <div className="px-4 py-5 userProfile text-center">
                <i className="bi bi-person-circle"></i>
              </div>
              <div className="py-5 px-4">
                <Row>
                  <Col xs={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.first_name}
                        {...register('first_name')}
                      />
                      <Form.Control.Feedback type="invalid">{errors.first_name?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Phone No.</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.phone}
                        {...register('phone')}
                      />
                      <Form.Control.Feedback type="invalid">{errors.phone?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                  <Col xs={6}>
                    <Form.Group className="mb-4">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        isInvalid={!!errors.last_name}
                        {...register('last_name')}
                      />
                      <Form.Control.Feedback type="invalid">{errors.last_name?.message}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        isInvalid={!!errors.email}
                        {...register('email')}
                        disabled
                      />
                      <Form.Control.Feedback type="invalid">{errors.email?.message}</Form.Control.Feedback>
                    </Form.Group>
                  </Col>
                </Row>
              </div>
            </Card>
          </div>
        </Form>
      </div>
    </>
  );
};

export default ProfileEdit;
