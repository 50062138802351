import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Moment from 'react-moment';
import {
  Card, Row, Col, Form, Image, Button, ListGroup, Spinner,
} from 'react-bootstrap';
import { ToastContainer, toast } from 'react-toastify';

import './subscriptionplancreate.css';
import visaIcon from '../../../assets/images/cards/visa-svgrepo-com.svg';
import masterIcon from '../../../assets/images/cards/mastercard-svgrepo-com.svg';
import discoverIcon from '../../../assets/images/cards/discover-svgrepo-com.svg';
import amexIcon from '../../../assets/images/cards/amex-3-svgrepo-com.svg';
import dinersIcon from '../../../assets/images/cards/diners-3-svgrepo-com.svg';
import jcbIcon from '../../../assets/images/cards/jcb-svgrepo-com.svg';
import unionpayIcon from '../../../assets/images/cards/unionpay-2-svgrepo-com.svg';
import { cardList, chargeCreate, planDetail } from '../../../services/BillingService';
import { getStoreSession } from '../../../services/StoreService';
import useAuth from '../../../hooks/useAuth';

const SubscriptionPlanCreate = () => {
  const title = 'VCOM Merchant: Subscription';
  const [isLoading, setIsLoading] = useState(false);
  const [plan, setPlan] = useState({});
  const [cards, setCards] = useState([]);
  const [intervalStr, setIntervalStr] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('');
  const date = new Date();
  const { getSession } = useAuth();
  const { planId } = useParams();
  const navigate = useNavigate();

  const getCards = async () => {
    try {
      const user = getSession();
      if (user && user.tenant_id) {
        const response = await cardList(user.tenant_id);
        if (response.success) {
          setCards(response.data);
        }
      }
    } catch (error) {
      setCards([]);
    }
  };

  const getPlan = async () => {
    try {
      if (planId) {
        const response = await planDetail(planId);
        if (response.success) {
          setPlan(response.data);
          setIntervalStr(response.data?.interval);
        }
      }
    } catch (error) {
      setPlan({});
    }
  };

  const handleDays = () => {
    switch (intervalStr) {
      case 'year':
        return 365;
      case 'month':
        return 30;
      case 'week':
        return 7;
      case 'day':
        return 1;
      default:
        return 30;
    }
  };

  const handlePlan = async () => {
    if (!selectedPaymentMethod) {
      toast.error('Please select at least one payment method.');
    } else {
      try {
        const store = getStoreSession();
        if (store && plan.id) {
          setIsLoading(true);
          const response = await chargeCreate({ store_id: store.id, payment_method_id: selectedPaymentMethod, plan_id: plan.id });
          if (response.success) {
            setIsLoading(false);
            toast.success(response.message);
            navigate('/merchant');
          } else {
            setIsLoading(false);
            toast.error(response.message);
          }
        } else {
          toast.error('Some error occured. Please try again.');
        }
      } catch (error) {
        toast.error(error.message);
      }
    }
  };

  useEffect(() => {
    (async () => {
      await getCards();
      await getPlan();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="planCreate minHeight">
        <div className="px-3 mx_0 m_0 mx_3 mx-5 pt-5">
          <h3 className="fs-5 mb-4">
            <Link to={'/merchant/subscription/plan/list'} className="backBtn"><i className="bi bi-arrow-left-short"></i></Link>
            Confirm payment for selected subscription plan
          </h3>
          <Row>
            <Col xs={12} md={8}>
              <Card className="p-4 mb-3">
                <Card.Title className="fs-6 mb-3">Payment method</Card.Title>
                <Card.Text className="text-muted">
                  Select the credit card you want to use to pay for your subscription.
                </Card.Text>
                <div className="chooseCard">
                  <Form>
                    {cards && cards.map((card) => <Form.Check key={card.id} className="d-flex align-items-center mb-3">
                      <Form.Check.Input type='radio' name="payment_method" value={card.id} onChange={(e) => setSelectedPaymentMethod(e.target.value)} />
                      <Form.Check.Label className="text-muted ms-3">
                      {card?.brand === 'visa' && <Image className="paymentIcon me-2" src={visaIcon} alt="visaIcon" />}
                      {card?.brand === 'mastercard' && <Image className="paymentIcon me-2" src={masterIcon} alt="masterIcon" />}
                      {card?.brand === 'discover' && <Image className="paymentIcon me-2" src={discoverIcon} alt="discoverIcon" />}
                      {card?.brand === 'amex' && <Image className="paymentIcon me-2" src={amexIcon} alt="amexIcon" />}
                      {(card?.brand === 'dinersclub' || card?.brand === 'diners') && <Image className="paymentIcon me-2" src={dinersIcon} alt="dinersIcon" />}
                      {card?.brand === 'jcb' && <Image className="paymentIcon me-2" src={jcbIcon} alt="jcbIcon" />}
                      {card?.brand === 'unionpay' && <Image className="paymentIcon me-2" src={unionpayIcon} alt="unionpayIcon" />}
                        {card.name} ending in ************{card.card_no}
                      </Form.Check.Label>
                    </Form.Check>)}
                  </Form>
                  <div className="p-4 text-end">
                     <Link to={'/merchant/billing'}>Add payment method</Link>
                  </div>
                </div>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <Card.Header className="border-0 bg-transparent pt-4 fs-6">{plan.name}</Card.Header>
                <Card.Body>
                  <Card.Text className="text-muted">{plan.currency} {plan.price} + tax every {handleDays()} {Number(handleDays()) === 1 ? 'day' : 'days'}</Card.Text>
                  <Card.Text>
                    As a result of this plan change, you now have a credit of {plan.currency} {plan.price} on your account.
                    This will cover your subscription until <Moment add={{ days: handleDays() }} format='YYYY-MM-DD'>{date}</Moment>.
                  </Card.Text>
                </Card.Body>
                <ListGroup variant="flush">
                  <ListGroup.Item className="border-top">
                    PLAN DETAILS
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <div className="d-flex justify-content-between mb-2">
                      <span>Subtotal</span><span>{plan.currency} {plan.price}</span>
                    </div>
                    <div className="d-flex justify-content-between">
                      <span>Plan change credit</span><span>-{plan.currency} {plan.price}</span>
                    </div>
                  </ListGroup.Item>
                </ListGroup>
                <Card.Footer className="bg-transparent border-top py-3">
                  <div className="d-flex justify-content-between mb-3">
                    <span className="fw-500">Billed now</span>
                    <span className="fw-500">{plan.currency} {plan.price}</span>
                  </div>
                  <Button type='button' className="btn btn-primary w-100" onClick={handlePlan} disabled={isLoading}>
                    <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                    <span className="px-2">Start plan</span>
                  </Button>
                </Card.Footer>
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default SubscriptionPlanCreate;
