import { useEffect, useState } from 'react';
import {
  Button, Form, Modal, Spinner, InputGroup, Row, Col,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import './addpaymentmethod.css';
import useAuth from '../../../hooks/useAuth';
import { countryList } from '../../../services/StoreService';
import { cardCreate } from '../../../services/BillingService';

const schema = yup
  .object({
    card_no: yup.string().label('Card number')
      .required()
      .min(14)
      .max(14),
    cvc: yup.string().label('CVC').required(),
    is_default: yup.boolean().default(true).label('Primary').optional(),
    name: yup.string().label('Full name').required(),
    expires_month: yup.string().label('Expires month').required(),
    expires_year: yup.string().label('Expires year').required(),
    country: yup.string().label('Country').optional(),
    city: yup.string().label('City').optional(),
    line1: yup.string().label('Street and house number').optional(),
    line2: yup.string().label('Apartment').optional(),
    postal_code: yup.string().label('Postal code').optional(),
  })
  .required();

const AddPaymentMethod = ({
  getCards,
  showAddPaymentMethod,
  handleShowAddPaymentMethod,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [countries, setCountries] = useState([]);
  const { getSession } = useAuth();

  const monthOptions = () => {
    const monthRows = [];
    /* eslint-disable-next-line no-plusplus */
    for (let i = 1; i <= 12; i += 1) {
      monthRows.push(<option key={i} value={i}>{i < 10 ? `0${i}` : i}</option>);
    }
    return monthRows;
  };

  const yearOptions = () => {
    const yearRows = [];
    const date = new Date();
    /* eslint-disable-next-line no-plusplus */
    for (let i = date.getFullYear(); i < date.getFullYear() + 25; i++) {
      yearRows.push(<option key={i} value={i}>{i}</option>);
    }
    return yearRows;
  };

  const getCountries = async () => {
    try {
      const response = await countryList();
      if (response.success) {
        setCountries(response.data);
      }
    } catch (error) {
      setCountries([]);
    }
  };

  const handleAddPaymentMethod = async (data) => {
    setIsLoading(true);
    try {
      const user = getSession();
      const requestJson = {
        tenant_id: user.tenant_id,
        card_no: data.card_no,
        cvc: data.cvc,
        name: data.name,
        expires_month: data.expires_month,
        expires_year: data.expires_year,
        is_default: data.is_default,
        billing_detail: {
          country: data.country,
          city: data.city,
          line1: data.line1,
          line2: data.line2,
          postal_code: data.postal_code,
        },
      };
      const response = await cardCreate(requestJson);
      if (response.success) {
        toast.success(response.message);
        await getCards();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error('Some error occured. Please try again later.');
    }
    setIsLoading(false);
    handleShowAddPaymentMethod();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setShow(showAddPaymentMethod);
  }, [showAddPaymentMethod]);

  useEffect(() => {
    (async () => {
      await getCountries();
    })();
  });

  return (
    <>
      <Modal className="modalCard" show={show} onHide={handleShowAddPaymentMethod}>
        <Form onSubmit={handleSubmit(handleAddPaymentMethod)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">Add payment method</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <h6 className="text13 mb-3">CREDIT CARD INFORMATION</h6>
            <Form.Label>Card Number<span className="text-danger">*</span></Form.Label>
            <InputGroup className="mb-3">
              <Form.Control
                type="number"
                aria-describedby="basic-addon1"
                isInvalid={!!errors.card_no}
                {...register('card_no')}
              />
              <InputGroup.Text id="basic-addon1"><i class="bi bi-lock-fill"></i></InputGroup.Text>
              <Form.Control.Feedback type="invalid">{errors.card_no?.message}</Form.Control.Feedback>
            </InputGroup>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Expires Month</Form.Label>
                  <Form.Select
                    isInvalid={!!errors.expires_month}
                    {...register('expires_month')}
                  >
                    <option value="">Choose month</option>
                    {monthOptions()}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.expires_month?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Expires Year</Form.Label>
                  <Form.Select
                    isInvalid={!!errors.expires_year}
                    {...register('expires_year')}
                  >
                    <option value="">Choose year</option>
                    {yearOptions()}
                  </Form.Select>
                  <Form.Control.Feedback type="invalid">{errors.expires_year?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>CVC<span className="text-danger">*</span><i class="bi bi-info-circle-fill"></i></Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Example: 456"
                    isInvalid={!!errors.cvc}
                    {...register('cvc')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.cvc?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label className="mb-4 pb-1"></Form.Label>
                  <Form.Check
                    type="switch"
                    label="Primary"
                    isInvalid={!!errors.is_default}
                    {...register('is_default')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.is_default?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <h6 className="text13 my-3">BILLING ADDRESS</h6>
            <Form.Group className="mb-3">
              <Form.Label>Country/Region</Form.Label>
              <Form.Select
                isInvalid={!!errors.country}
                {...register('country')}
              >
                <option value="">Choose one</option>
                {countries && countries.map((country) => <option key={country.id} value={country.country_code}>{country.name}</option>)}
              </Form.Select>
              <Form.Control.Feedback type="invalid">{errors.country?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Full name<span className="text-danger">*</span></Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter full name"
                isInvalid={!!errors.name}
                {...register('name')}
              />
              <Form.Control.Feedback type="invalid">{errors.name?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Street and house number</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                isInvalid={!!errors.line1}
                {...register('line1')}
              />
              <Form.Control.Feedback type="invalid">{errors.line1?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Apartment, suite, etc.</Form.Label>
              <Form.Control
                type="text"
                placeholder="Enter address"
                isInvalid={!!errors.line2}
                {...register('line2')}
              />
              <Form.Control.Feedback type="invalid">{errors.line2?.message}</Form.Control.Feedback>
            </Form.Group>
            <Row>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>Postal code</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter postal code"
                    isInvalid={!!errors.postal_code}
                    {...register('postal_code')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.postal_code?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group className="mb-3">
                  <Form.Label>City/town</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter city"
                    isInvalid={!!errors.city}
                    {...register('city')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.city?.message}</Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" variant="primary" className="cancel" onClick={handleShowAddPaymentMethod}>
              Close
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading}>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">Save</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddPaymentMethod;
