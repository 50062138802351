import { useEffect } from 'react';
import {
  Row,
  Col,
  Image,
  Form,
  Button,
  Card,
} from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { toast } from 'react-toastify';

import Storename from '../../../assets/images/storename.png';
import Industry from '../../../assets/images/industry.png';
import ecommerceAccount from '../../../assets/images/ecommerce-account.png';
import { storeUpdate } from '../../../services/StoreService';

const schema = yup
  .object({
    store_name: yup.string().label('Store name').optional(),
    industry: yup.string().label('Industry').required(),
    shopify_store_email: yup.string().label('Connected E-Commerce Account').optional(),
  })
  .required();

const EditBasicInformation = ({ store, handleBasicInfo, getStoreDetail }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleStore = async (data) => {
    // eslint-disable-next-line
    console.log('clicked', data);
    const postData = {
      store_id: store.id,
      industry: data.industry,
    };
    try {
      const response = await storeUpdate(postData);
      if (response.success) {
        toast.success(response.message);
        await getStoreDetail();
        handleBasicInfo();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    const fields = [
      'store_name',
      'industry',
      'shopify_store_email',
    ];
    fields.forEach((field) => {
      setValue(field, store[field]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Card>
      <Form onSubmit={handleSubmit(handleStore)} noValidate>
        <div className="cardTitle">
          <Row className="align-items-center">
            <Col><h3 className="fs-6 mb-0">Basic Information</h3></Col>
            <Col className="text-end storeBtn">
              <Button type='button' onClick={handleBasicInfo} className="cancel">Cancel</Button>
              <Button className="ms-3" variant="primary" type="submit">
                Save
              </Button>
            </Col>
          </Row>
        </div>
        <div className="cardStore">
          <Row>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={Storename} alt="Storename" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Store Name</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.store_name}
                    {...register('store_name')}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{errors.store_name?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={Industry} alt="Industry" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Industry</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.industry}
                    {...register('industry')}
                  />
                  <Form.Control.Feedback type="invalid">{errors.industry?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
            <Col xs={12} md={6}>
              <div className="d-flex align-items-center gap12">
                <Image src={ecommerceAccount} alt="ecommerceAccount" />
                <Form.Group className="mb-3 w-100">
                  <Form.Label className="mb-0 text-muted text13">Connected E-Commerce Account</Form.Label>
                  <Form.Control
                    type="text"
                    isInvalid={!!errors.shopify_store_email}
                    {...register('shopify_store_email')}
                    disabled
                  />
                  <Form.Control.Feedback type="invalid">{errors.shopify_store_email?.message}</Form.Control.Feedback>
                </Form.Group>
              </div>
            </Col>
          </Row>
        </div>
      </Form>
    </Card>
  );
};

export default EditBasicInformation;
