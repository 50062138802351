import { useEffect, useState } from 'react';
import {
  Modal, Button, Image,
} from 'react-bootstrap';

import './thememodal.css';
import { themeList } from '../../../services/SolutionService';
import noImageIcon from '../../../assets/images/no-image-icon.png';

const ThemeModal = ({ showThemeModal, handleShowThemeModal, handleSelectedTheme }) => {
  const [show, setShow] = useState(false);
  const [themes, setThemes] = useState([]);

  const getThemes = async () => {
    try {
      const response = await themeList();
      if (response.success) {
        setThemes(response.data);
      } else {
        setThemes([]);
      }
    } catch (error) {
      setThemes([]);
    }
  };

  useEffect(() => {
    setShow(showThemeModal);
  }, [showThemeModal]);

  useEffect(() => {
    (async () => {
      await getThemes();
    })();
  }, []);

  return (
    <Modal className="storeModal" show={show} onHide={handleShowThemeModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Store Design Themes</Modal.Title>
      </Modal.Header>
      <Modal.Body className="row">
      {themes && themes.map((theme) => (
        <div key={theme.id} className="col-md-3 pb-3">
          <Image src={theme.wallpaper_url ? theme.wallpaper_url : noImageIcon} thumbnail onClick={() => handleSelectedTheme(theme)} />
          <span>{theme.name}</span>
        </div>
      ))}
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" variant="primary" onClick={handleShowThemeModal}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ThemeModal;
