import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { variantRemoveTextureModal, productRemoveThreeDModal } from '../../../services/ProductService';

const RemoveThreeDModal = ({
  selectedOption, selectedVariant, showRemoveThreeDModal, handleShowRemoveThreeDModel, getProduct,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveThreeDModal = async () => {
    setIsLoading(true);
    try {
      let response = null;
      if (selectedOption === 1) {
        response = await productRemoveThreeDModal({ product_id: selectedVariant });
      } else {
        response = await variantRemoveTextureModal({ variant_id: selectedVariant });
      }
      setIsLoading(false);
      if (response.success) {
        await getProduct();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    handleShowRemoveThreeDModel();
  };

  useEffect(() => {
    setShow(showRemoveThreeDModal);
  }, [showRemoveThreeDModal]);

  return (
    <Modal className="maxWidth" show={show} onHide={handleShowRemoveThreeDModel}>
      <Modal.Header closeButton>
        {selectedOption === 1 && <Modal.Title>Delete 3D-Model</Modal.Title>}
        {selectedOption !== 1 && <Modal.Title>Delete Texture</Modal.Title>}
      </Modal.Header>
      <Modal.Body>
        {selectedOption === 1 && <p className="mb-0">This will delete 3D-Model. Are you sure?</p>}
        {selectedOption !== 1 && <p className="mb-0">This will delete selected variant texture. Are you sure?</p>}
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" variant="secondary" onClick={handleShowRemoveThreeDModel}>
          No
        </Button>
        <Button variant="primary" onClick={handleRemoveThreeDModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Yes</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveThreeDModal;
