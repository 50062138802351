import {
  Col, Row,
} from 'react-bootstrap';

const AppList = () => (
  <>
    <Row>
      <Col xs={12} md={12}>
        <div className="d-flex align-items-center mb_2 py-2 myProduct">
          <h6 className="text-white mb-0 me-2">Apps</h6>
        </div>
      </Col>
    </Row>
    <Row>
      <Col xs={12} md={12} className="productListTable">
        <p>Show Apps here</p>
      </Col>
    </Row>
  </>
);

export default AppList;
