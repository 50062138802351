import { useEffect, useState } from 'react';
import {
  Modal, Button, Spinner,
  Card,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import DropdownTreeSelect from 'react-dropdown-tree-select';

// import 'react-dropdown-tree-select/dist/styles.css';
import { mapProductCategory, storeMarketCategoryListByParent } from '../../../services/ProductService';
import { getStoreSession } from '../../../services/StoreService';

const SetCategoryModal = ({
  selectedRows, showCategoryModal, handleShowCategoryModal, handleToggleCleared,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const store = getStoreSession();

  const onCategoryChange = (currentNode, selectedNodes) => {
    const selectedIds = selectedNodes.map((node) => node.id);
    setSelectedCategories(selectedIds);
    // console.log('onChange::', currentNode, selectedNodes);
  };

  const getStoreCategories = async (parentId, status) => {
    let foundCategories = [];
    try {
      const response = await storeMarketCategoryListByParent(store.id, parentId, status);
      if (response.success) {
        foundCategories = response.data;
      } else {
        foundCategories = [];
      }
    } catch (error) {
      foundCategories = [];
    }
    return foundCategories;
  };

  const getCategoryTreeFromParentView = async (parentId, status) => {
    const categoryArr = [];
    const existCategories = await getStoreCategories(parentId, status);
    await Promise.all(existCategories.map(async (eachCategory) => {
      const category = eachCategory;
      // eslint-disable-next-line no-await-in-loop
      const subCategory = await getCategoryTreeFromParentView(eachCategory.id, status);
      if (subCategory && subCategory !== '') {
        if (subCategory.length > 0) {
          category.children = subCategory;
        } else {
          category.children = [];
        }
        category.label = eachCategory.name;
        category.value = eachCategory.id;
        category.checked = selectedCategories.includes(eachCategory.id);
        categoryArr.push(category);
      }
    }));
    return categoryArr;
  };

  const getCategories = async () => {
    const categoriesData = await getCategoryTreeFromParentView(0, true);
    setCategories(categoriesData);
  };

  const handleCategoryModal = async () => {
    setIsLoading(true);
    try {
      const response = await mapProductCategory({
        store_id: store.id,
        product_ids: selectedRows,
        category_ids: selectedCategories,
      });
      if (response.success) {
        handleToggleCleared();
        setIsLoading(false);
        handleShowCategoryModal();
        toast.success('Categories added successfully.');
      } else {
        setIsLoading(false);
        handleShowCategoryModal();
        toast.error(response.message);
      }
    } catch (error) {
      setIsLoading(false);
      handleShowCategoryModal();
      toast.error(error.message);
    }
  };

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setShow(showCategoryModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showCategoryModal]);

  return (
    <Modal show={show} onHide={handleShowCategoryModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 modal-title h4">Set Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Card>
        <DropdownTreeSelect
          texts={{ placeholder: 'Select Categories' }}
          data={categories}
          className="choose-category"
          onChange={onCategoryChange}
          searchPredicate={(node, searchTerm) => node.label && node.label.toLower().indexOf(searchTerm) >= 0}
          mode='multiSelect'
          showDropdown="default"
          showPartiallySelected={true}
          keepTreeOnSearch={true}
          keepChildrenOnSearch={true}
          keepOpenOnSelect={true}
          clearSearchOnChange={true}
        />
        </Card>
      </Modal.Body>
      <Modal.Footer>
        <Button type="button" className="cancel" variant="secondary" onClick={handleShowCategoryModal}>
          Cancel
        </Button>
        <Button type="button" variant="primary" onClick={handleCategoryModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Set</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default SetCategoryModal;
