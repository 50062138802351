import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Form, FormSelect, FormLabel, Spinner, Image,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import useAuth from '../../hooks/useAuth';
import { storeList, setStore } from '../../services/StoreService';
import './login.css';
import logo from '../../assets/images/logo.png';

const schema = yup
  .object({
    store: yup.string().label('Store').required(),
  })
  .required();

const StoreList = () => {
  const title = 'Stores';
  const [isLoading, setIsLoading] = useState(false);
  const [stores, setStores] = useState([]);
  const { getSession } = useAuth();
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const getStores = async () => {
    const user = getSession();
    if (user && user.tenant_id) {
      const response = await storeList(user.tenant_id);
      // eslint-disable-next-line
      // console.log(response);
      if (response.success) {
        if (response.data.length === 1) {
          await setStore(response.data[0]);
          navigate('/merchant');
        }
        setStores(response.data);
      }
    }
  };

  const handleStore = async (data) => {
    try {
      setIsLoading(true);
      const result = stores.filter((store) => store.id === data.store);
      const response = await setStore(result);
      setIsLoading(false);
      toast.success(response);
      navigate('/merchant');
    } catch (err) {
      toast.error(err.message);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getStores();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <main className="d-flex align-items-center min-vh-100 p-3">
        <div className="loginCard">
          <Form className="row g-2" onSubmit={handleSubmit(handleStore)} noValidate>
            <div className="col-md-12">
              <div className="text-center">
                <Link to={'/'}>
                  <Image src={logo} alt="logo" />
                </Link>
                <h3 className="mb-2">Select Store</h3>
              </div>
             <Form.Group>
                <FormLabel className="mb-1">Stores</FormLabel>
                <FormSelect
                  {...register('store')}
                  isInvalid={!!errors.store}
                >
                  <option value="">Choose one</option>
                  {stores && stores.map((store) => <option key={store.id} value={store.id}>{store.store_name}</option>)}
                </FormSelect>
                <Form.Control.Feedback type="invalid">{errors.store?.message}</Form.Control.Feedback>
              </Form.Group>
              <Button className="w-100 btn btn-lg btn-primary mt-3"
                type="submit"
                disabled={isLoading}
              >
                <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
                <span className="px-2">Submit</span>
              </Button>
              <p className="mt-4 text-center"><Link to={'/login'}>Back</Link></p>
            </div>
          </Form>
        </div>
      </main>
      <ToastContainer />
    </>
  );
};

export default StoreList;
