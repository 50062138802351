export default function File({ item }) {
  return (
    <p
      key={item.id}
      style={{
        cursor: 'pointer',
      }}
    >
      {item.name}
    </p>
  );
}
