import { useEffect, useState } from 'react';
import {
  Button, Form, Image, Modal, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { chargeUpdate } from '../../../services/BillingService';
import visaIcon from '../../../assets/images/cards/visa-svgrepo-com.svg';
import masterIcon from '../../../assets/images/cards/mastercard-svgrepo-com.svg';
import discoverIcon from '../../../assets/images/cards/discover-svgrepo-com.svg';
import amexIcon from '../../../assets/images/cards/amex-3-svgrepo-com.svg';
import dinersIcon from '../../../assets/images/cards/diners-3-svgrepo-com.svg';
import jcbIcon from '../../../assets/images/cards/jcb-svgrepo-com.svg';
import unionpayIcon from '../../../assets/images/cards/unionpay-2-svgrepo-com.svg';

const ChangePaymentMethod = ({
  chargeId,
  cards,
  showChangePaymentMethod,
  handleShowChangePaymentMethod,
  getCharge,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [paymentMehodId, setPaymentMethodId] = useState('');

  const handleChangePaymentMethod = async () => {
    if (chargeId && paymentMehodId) {
      setIsLoading(true);
      try {
        const response = await chargeUpdate({ charge_id: chargeId, payment_method_id: paymentMehodId });
        if (response.success) {
          toast.success(response.message);
          await getCharge();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error('Some error occured. Please try again later.');
      }
      setIsLoading(false);
    } else {
      toast.error('Please enter all the required details.');
    }
    handleShowChangePaymentMethod();
  };

  useEffect(() => {
    setShow(showChangePaymentMethod);
  }, [showChangePaymentMethod]);

  return (
    <>
      <Modal show={show} onHide={handleShowChangePaymentMethod}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Change payment method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        {cards && cards.map((card) => (
          <div key={card.id} className="mb-0">
            <Form.Check type='radio' className="d-flex align-items-center">
              <Form.Check.Input className="me-2" type="radio" name="payment_method_id" value={card.id} onChange={(e) => setPaymentMethodId(e.target.value)} />
              <Form.Check.Label>
                <div className="d-flex align-items-center">
                  {card.brand === 'visa' && <Image className="paymentIcon" src={visaIcon} alt="visaIcon" />}
                  {card.brand === 'mastercard' && <Image className="paymentIcon" src={masterIcon} alt="masterIcon" />}
                  {card.brand === 'discover' && <Image className="paymentIcon" src={discoverIcon} alt="discoverIcon" />}
                  {card.brand === 'amex' && <Image className="paymentIcon" src={amexIcon} alt="amexIcon" />}
                  {(card.brand === 'dinersclub' || card.brand === 'diners') && <Image className="paymentIcon" src={dinersIcon} alt="dinersIcon" />}
                  {card.brand === 'jcb' && <Image className="paymentIcon" src={jcbIcon} alt="jcbIcon" />}
                  {card.brand === 'unionpay' && <Image className="paymentIcon" src={unionpayIcon} alt="unionpayIcon" />}
                  <p className="mb-0 ms-3">{card.name} Ending in {`************${card.card_no || ''}`}</p>
                </div>
              </Form.Check.Label>
              <Form.Control.Feedback type="invalid">Please choose one payment method</Form.Control.Feedback>
            </Form.Check>
          </div>
        ))}
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowChangePaymentMethod}>
            Close
          </Button>
          <Button variant="primary" onClick={handleChangePaymentMethod} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Save</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ChangePaymentMethod;
