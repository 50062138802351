import {
  Card, Button, Row, Col,
} from 'react-bootstrap';

const ProfileView = ({ tenant, handleIsEdit }) => {
  const title = 'Profile';

  return (
    <>
      <div className="profilePage minHeight">
        <div className="px-3 mx_0 mx-5 pt-5">
          <div className="d-flex justify-content-between align-items-center">
            <h2 className="title mb-0">{title}</h2>
            <Button className="cancel" onClick={() => handleIsEdit()}>Edit</Button>
          </div>
          <Card className="d-flex mt-3">
            <div className="px-4 py-5 userProfile text-center">
              <i className="bi bi-person-circle"></i>
              <Card.Title>{tenant.first_name} {tenant.last_name}</Card.Title>
            </div>
            <div className="py-5 px-4">
              <Row>
                <Col xs={12} md={4}>
                  <div className="icon-with-text d-flex align-items-center gap12">
                    <i className="bi bi-person-circle"></i>
                    <div className="basicText">
                      <span className="text-muted">Name</span>
                      <h6>{tenant.first_name} {tenant.last_name}</h6>
                    </div>
                  </div>
                </Col>
                <Col className="my_2" xs={12} md={4}>
                  <div className="icon-with-text d-flex align-items-center gap12">
                    <i className="bi bi-telephone-fill"></i>
                    <div className="basicText">
                      <span className="text-muted">Phone No.</span>
                      <h6>{tenant.phone || '-'}</h6>
                    </div>
                  </div>
                </Col>
                <Col xs={12} md={4}>
                  <div className="icon-with-text d-flex align-items-center gap12">
                    <i className="bi bi-envelope-fill"></i>
                    <div className="basicText">
                      <span className="text-muted">Email</span>
                      <h6>{tenant.email}</h6>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </Card>
        </div>
      </div>
    </>
  );
};

export default ProfileView;
