import {
  Row,
  Col,
  Image,
  Card,
  Button,
} from 'react-bootstrap';
import path from 'path';
import storeType from '../../../assets/images/storeType.png';
import backgroundImage from '../../../assets/images/backgroundImage.png';
import backgroundVideo from '../../../assets/images/backgroundVideo.png';
import storeDescription from '../../../assets/images/storeDescription.png';

const MarketSetting = ({ store, handleMarketSetting }) => (
  <Card className="my-4">
    <div className="cardTitle">
      <Row>
        <Col>
          <h3 className="fs-6 mb-1">VCOM Market Settings</h3>
          <p className="text-muted mb-0 text13">Change how your store get presented to customer on the VCOM Market.</p>
        </Col>
        <Col className="text-end"><Button onClick={handleMarketSetting} className="edit btn-link p-0 bg-transparent border-0">Edit</Button></Col>
      </Row>
    </div>
    <div className="cardStore">
      <Row>
        <Col xs={6}>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={storeType} alt="storeType" />
            <div className="basicText">
              <span className="text-muted text13">Store Type</span>
              <h6>{store.StoreType?.name || '-'}</h6>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={backgroundImage} alt="backgroundImage" />
            <div className="basicText">
              <span className="text-muted text13">Background Image</span>
              <h6>{store.store_image ? path.basename(store.store_image) : '-'}</h6>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col xs={6}>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={backgroundVideo} alt="backgroundVideo" />
            <div className="basicText">
              <span className="text-muted text13">Background Video</span>
              <h6>{store.store_video ? path.basename(store.store_video) : '-'}</h6>
            </div>
          </div>
        </Col>
        <Col xs={6}>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={storeDescription} alt="storeDescription" />
            <div className="basicText">
              <span className="text-muted text13">Store Presentation</span>
              <p className="mb-0">{store.store_description || '-'}</p>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Card>
);

export default MarketSetting;
