import { useEffect, useState } from 'react';
import { Modal, Button, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';

const SolutionPublishModal = ({
  showSolutionPublish,
  handleShowSolutionPublish,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSolutionPublish = async () => {
    setIsLoading(true);
    toast.success('Solution has been published.');
    setIsLoading(false);
    handleShowSolutionPublish();
  };

  useEffect(() => {
    setShow(showSolutionPublish);
  }, [showSolutionPublish]);

  return (
    <>
      <Modal show={show} onHide={handleShowSolutionPublish}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Publish Solution</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>Are you sure you want to Publish?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowSolutionPublish}>
            No
          </Button>
          <Button variant="primary" onClick={handleSolutionPublish} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default SolutionPublishModal;
