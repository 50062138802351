import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { categoryRemove } from '../../../services/ProductService';

const RemoveCategoryModal = ({
  selectedCategory, getCategories, showRemoveCategoryModal, handleShowRemoveCategoryModal,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveCategoryModal = async () => {
    setIsLoading(true);
    try {
      const response = await categoryRemove({ id: selectedCategory.id, status: false });
      setIsLoading(false);
      if (response.success) {
        await getCategories();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    handleShowRemoveCategoryModal();
  };

  useEffect(() => {
    setShow(showRemoveCategoryModal);
  }, [showRemoveCategoryModal]);

  return (
    <Modal className="maxWidth" show={show} onHide={handleShowRemoveCategoryModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 modal-title h4">Delete Category</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure that you want to delete this category?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" variant="secondary" onClick={handleShowRemoveCategoryModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleRemoveCategoryModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Yes</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveCategoryModal;
