import { Helmet } from 'react-helmet';
import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import { useNavigate, Link } from 'react-router-dom';
import {
  Card, Tab, Tabs, Row, Col, Form, InputGroup, ButtonGroup, Button,
  Image, Badge, Dropdown, DropdownButton,
} from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';
import Moment from 'react-moment';
import DataTable from 'react-data-table-component';

import './productlist.css';
import { getStoreSession } from '../../../services/StoreService';
import { productList } from '../../../services/ProductService';
import uploadedThreeDModelImg from '../../../assets/images/uploaded_3d_model.png';
import noThreeDModelImg from '../../../assets/images/no_3d_model.png';
import noImageIconImg from '../../../assets/images/no-image-icon.png';
import MultiProductStatusModal from '../../../components/merchant/product/MultiProductStatusModal';
import SetCategoryModal from '../../../components/merchant/product/SetCategoryModal';

const ProductList = () => {
  const title = 'Products';
  const [activeTab, setActiveTab] = useState('all');
  const [selectedSearchText, setSelectedSearchText] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedProductStatus, setSelectedProductStatus] = useState('');
  const [products, setProducts] = useState([]);
  const [filterProducts, setFilterProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [productStatus, setProductStatus] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableClass, setTableClass] = useState('');
  const [showSatusModal, setShowStatusModal] = useState(false);
  const [choosedStatus, setChoosedStatus] = useState('');
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const navigate = useNavigate();

  const columns = [
    {
      name: '3D Model',
      selector: (row) => <Image src={row.ThreeDModel?.file_url ? uploadedThreeDModelImg : noThreeDModelImg} thumbnail rounded alt='model'/>,
      width: '90px',
    },
    {
      name: 'Image',
      selector: (row) => <Image src={row.Media?.[0]?.file_url ? row.Media?.[0]?.file_url : noImageIconImg} thumbnail rounded alt='model' width={50} height={50} />,
      width: '90px',
    },
    {
      name: 'Product',
      selector: (row) => <Link to={`/merchant/product/detail/${row.id}`}>{row.name}</Link>,
      width: '220px',
    },
    {
      name: 'Type',
      selector: (row) => row.product_type,
    },
    {
      name: 'Status',
      selector: (row) => getProductStatusBadge(row.product_status),
    },
    {
      name: 'Vendor',
      selector: (row) => row.vendor,
    },
    {
      name: 'Last Synchronized',
      selector: (row) => <Moment format='DD-MM-YYYY HH:mm A'>{row.created_at}</Moment>,
    },
  ];

  const handleRowSelected = useCallback((state) => {
    const selectedIds = state.selectedRows.map((row) => row.id);
    setSelectedRows(selectedIds);
    if (selectedIds.length > 0) {
      setTableClass('product-table');
    } else {
      setTableClass('');
    }
  }, []);

  const handleShowStatusModal = () => {
    setShowStatusModal(!showSatusModal);
  };

  const handleShowCategoryModal = () => {
    setShowCategoryModal(!showCategoryModal);
  };

  const handleToggleCleared = () => {
    setTableClass('');
    setToggleCleared(!toggleCleared);
  };

  const contextActions = useMemo(() => {
    const handleSetStatus = (status) => {
      setChoosedStatus(status);
      handleShowStatusModal();
    };

    const handleSetCategory = () => {
      handleShowCategoryModal();
    };

    return (
      <DropdownButton key="moreaction" title="More Action" className="moreAction">
        <Dropdown.Item as="button" onClick={() => handleSetStatus(1)}>Set as Draft</Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => handleSetStatus(2)}>Set as Active</Dropdown.Item>
        <Dropdown.Item as="button" onClick={() => handleSetStatus(3)}>Set as Archived</Dropdown.Item>
        <Dropdown.Item as="button" onClick={handleSetCategory}>Set Category</Dropdown.Item>
      </DropdownButton>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getProducts = async () => {
    const store = getStoreSession();
    if (store) {
      try {
        let response = null;
        if (selectedProductStatus) {
          response = await productList(store.id, selectedProductStatus);
        } else {
          response = await productList(store.id);
        }
        if (response.success) {
          setProducts(response.data);
        } else {
          setProducts([]);
        }
      } catch (error) {
        setProducts([]);
      }
    } else {
      navigate('/');
    }
  };

  const getVendors = () => {
    const uniqueVendors = products
      .map((product) => product.vendor)
      .filter((value, index, self) => self.indexOf(value) === index);
    setVendors(uniqueVendors);
  };

  const getProductTypes = () => {
    const uniqueProductTypes = products
      .map((product) => product.product_type)
      .filter((value, index, self) => self.indexOf(value) === index);
    setProductTypes(uniqueProductTypes);
  };

  const getProductStatus = () => {
    setProductStatus([{ id: 1, name: 'Draft' }, { id: 2, name: 'Active' }, { id: 3, name: 'Archive' }]);
  };

  const getFilteredProducts = () => {
    if (selectedVendor || selectedProductType || selectedSearchText) {
      if (selectedVendor && selectedProductType && selectedSearchText) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.product_type === selectedProductType
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedProductType) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.product_type === selectedProductType);
        setFilterProducts(filterProds);
      } else if (selectedVendor && selectedSearchText) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedProductType && selectedSearchText) {
        const filterProds = products.filter((product) => product.product_type === selectedProductType
          && product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      } else if (selectedVendor) {
        const filterProds = products.filter((product) => product.vendor === selectedVendor);
        setFilterProducts(filterProds);
      } else if (selectedProductType) {
        const filterProds = products.filter((product) => product.product_type === selectedProductType);
        setFilterProducts(filterProds);
      } else if (selectedSearchText) {
        const filterProds = products.filter((product) => product.name.toLowerCase().includes(selectedSearchText.toLowerCase()));
        setFilterProducts(filterProds);
      }
    } else {
      setFilterProducts(products);
    }
  };

  const getProductStatusBadge = (status) => {
    let badge = <Badge bg="success">Active</Badge>;
    if (status === 2) {
      badge = <Badge bg="success">Active</Badge>;
    } else if (status === 3) {
      badge = <Badge bg="danger">Archived</Badge>;
    } else {
      badge = <Badge bg="info">Draft</Badge>;
    }
    return badge;
  };

  const handleClear = () => {
    setSelectedProductStatus('');
    setSelectedProductType('');
    setSelectedVendor('');
    setSelectedSearchText('');
  };

  const renderTableHeaderTitle = () => (
    <div className="pt-3 pb-2 pe-2">
      <div className="searchBar">
        <Row>
          <Col sm={6}>
            <Form>
              <InputGroup>
                <InputGroup.Text><i className="bi bi-search"></i></InputGroup.Text>
                <Form.Control
                  placeholder="Enter Search then press enter key"
                  onChange={(e) => setSelectedSearchText(e.target.value)}
                  value={selectedSearchText}
                />
              </InputGroup>
            </Form>
          </Col>
          <Col sm={6}>
            <ButtonGroup aria-label="Basic example">
              <Form.Select
                onChange={(e) => setSelectedProductType(e.target.value)} value={selectedProductType}>
                <option value="">Type</option>
                {productTypes
                  && productTypes.map((productType) => (<option key={productType} value={productType}>
                    {productType}</option>))}
              </Form.Select>
              <Form.Select
                onChange={(e) => setSelectedProductStatus(e.target.value)}
                value={selectedProductStatus}
              >
                <option value="">Status</option>
                {productStatus
                  && productStatus.map((productSts) => (
                  <option key={productSts.id} value={productSts.id}>{productSts.name}</option>))}
              </Form.Select>
              <Form.Select
                onChange={(e) => setSelectedVendor(e.target.value)}
                value={selectedVendor}
              >
                <option value="">Vendor</option>
                {vendors && vendors.map((vendor) => <option key={vendor} value={vendor}>{vendor}</option>)}
              </Form.Select>
              <Button type="button" onClick={handleClear} variant="outline-light">Clear</Button>
            </ButtonGroup>
          </Col>
        </Row>
      </div>
    </div>
  );

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductStatus]);

  useEffect(() => {
    getProductStatus();
    getVendors();
    getProductTypes();
    getFilteredProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [products]);

  useEffect(() => {
    if (products) {
      getFilteredProducts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedProductType, selectedVendor, selectedSearchText]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="productList productTable">
        <div className="px-3 mx_3 m_0 mx-5 pt-5">
          <h1 className="title mb-2">{title}</h1>
          <p className="text-muted">Activate your imported products to make them available in your VR-store.</p>
          <Card className={tableClass}>
            <Row>
              <Col sm={12}>
                <Tabs
                  activeKey={activeTab}
                  onSelect={(k) => setActiveTab(k)}
                  className="tabs"
                >
                  <Tab eventKey="all" title="All">
                    <DataTable
                      title={renderTableHeaderTitle()}
                      columns={columns}
                      data={filterProducts}
                      selectableRows
                      contextActions={contextActions}
                      onSelectedRowsChange={handleRowSelected}
                      clearSelectedRows={toggleCleared}
                      responsive
                    />
                  </Tab>
                </Tabs>
              </Col>
              <Col sm={12}>
                <div className="mt-4 mb-4 text-center">
                  <Link className="infoColor text-decoration-none" to={'https://vcomverse.io/docs/products/'} target="_blank" rel="noopener noreferrer"><i className="bi bi-question-circle-fill me-1"></i>Learn more about products<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
                </div>
              </Col>
            </Row>
          </Card>
        </div>
      </div>
      <ToastContainer />
      <MultiProductStatusModal
        choosedStatus={choosedStatus}
        selectedRows={selectedRows}
        showSatusModal={showSatusModal}
        handleShowStatusModal={handleShowStatusModal}
        handleToggleCleared={handleToggleCleared}
        getProducts={getProducts}
      />
      <SetCategoryModal
        selectedRows={selectedRows}
        showCategoryModal={showCategoryModal}
        handleShowCategoryModal={handleShowCategoryModal}
        handleToggleCleared={handleToggleCleared}
      />
    </>
  );
};

export default ProductList;
