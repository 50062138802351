import { Outlet } from 'react-router-dom';

import Footer from '../components/Footer';
import OtherNavbar from '../components/other/OtherNavbar';

function OtherLayout() {
  return (
    <>
      <OtherNavbar />
      <Outlet />
      <Footer />
    </>
  );
}

export default OtherLayout;
