import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import {
  Badge, Card, Row, Col, Form, Button, Table, Image, ListGroup, ButtonGroup,
} from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify';
import moment from 'moment';
import DropdownTreeSelect from 'react-dropdown-tree-select';

import './productdetail.css';
import 'react-dropdown-tree-select/dist/styles.css';
import noImageIconImg from '../../../assets/images/no-image-icon.png';
import uploadedTDModelImg from '../../../assets/images/uploaded_3d_model.png';
import {
  productDetail, productUpdate, productUploadThreeDModal, variantUploadTextureModal, storeMarketCategoryListByParent,
  mapProductCategory,
} from '../../../services/ProductService';
import { getStoreSession } from '../../../services/StoreService';
import ProductStatusAlertModal from '../../../components/merchant/product/ProductStatusAlertModal';
import AddAnchorModal from '../../../components/merchant/product/AddAnchorModal';
import EditAnchorModal from '../../../components/merchant/product/EditAnchorModal';
import RemoveAnchorModal from '../../../components/merchant/product/RemoveAnchorModal';
import RemoveThreeDModal from '../../../components/merchant/product/RemoveThreeDModal';

const ProductDetail = () => {
  const title = 'Product Detail';
  const [product, setProduct] = useState({});
  const [showStatusAlert, setShowStatusAlert] = useState(false);
  const [scheduleDate, setScheduleDate] = useState('');
  const [scheduleTime, setScheduleTime] = useState('');
  const [showAddAnchorModal, setShowAddAnchorModal] = useState(false);
  const [showEditAnchorModal, setShowEditAnchorModal] = useState(false);
  const [showRemoveAnchorModal, setShowRemoveAnchorModal] = useState(false);
  const [selectedAnchor, setSelectedAnchor] = useState('');
  const [selectedOption, setSelectedOption] = useState(1);
  const [selectedVariant, setSelectedVariant] = useState('');
  const [showRemoveThreeDModal, setShowRemoveThreeDModal] = useState(false);
  const [categories, setCategories] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const { productId } = useParams();
  const store = getStoreSession();

  const getProduct = async () => {
    if (productId) {
      try {
        const response = await productDetail(productId);
        if (response.success) {
          setProduct(response.data);
          if (response.data.schedule_availability) {
            setScheduleDate(moment.utc(response.data.schedule_availability).format('YYYY-MM-DD'));
            setScheduleTime(moment.utc(response.data.schedule_availability).format('HH:mm'));
          }
          if (response.data.ProductCategory) {
            const selectedIds = response.data.ProductCategory.map((eachCategory) => eachCategory?.MarketCategory?.id);
            setSelectedCategories(selectedIds);
          }
        } else {
          setProduct({});
        }
      } catch (error) {
        setProduct({});
      }
    }
  };

  const renderProductStatus = () => {
    let statusStr = 'Draft';
    let statusClassStr = 'info';
    if (product.product_status === 1) {
      statusStr = 'Draft';
      statusClassStr = 'info';
    } else if (product.product_status === 2) {
      statusStr = 'Active';
      statusClassStr = 'success';
    } else if (product.product_status === 3) {
      statusStr = 'Archived';
      statusClassStr = 'danger';
    }
    return <Badge pill bg={statusClassStr} className="ms-2">{statusStr}</Badge>;
  };

  const handleShowStatusAlert = () => {
    setShowStatusAlert(!showStatusAlert);
  };

  const handleShowAddAnchorModal = () => {
    setShowAddAnchorModal(!showAddAnchorModal);
  };

  const handleShowEditAnchorModal = () => {
    setShowEditAnchorModal(!showEditAnchorModal);
  };

  const handleShowRemoveAnchorModal = () => {
    setShowRemoveAnchorModal(!showRemoveAnchorModal);
  };

  const handleShowAnchorModal = (data, i) => {
    setSelectedAnchor(data);
    if (i === 'edit') {
      handleShowEditAnchorModal();
    } else if (i === 'remove') {
      handleShowRemoveAnchorModal();
    }
  };

  const handleProductStatus = (status) => {
    setProduct({ ...product, product_status: status });
    handleShowStatusAlert();
  };

  const updateProduct = async (data) => {
    try {
      const response = await productUpdate(data);
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleScheduleDateTime = async () => {
    await updateProduct({ id: product.shopify_product_id, schedule_availability: `${scheduleDate} ${scheduleTime}` });
  };

  const handleUploadThreeDModel = async (selectedFiles) => {
    try {
      const formData = new FormData();
      formData.append('thememodel', selectedFiles[0]);
      const response = await productUploadThreeDModal(formData, store.id, product.id);
      if (response) {
        toast.success(response.message);
        await getProduct();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleUploadTextureModel = async (selectedFiles, variantId) => {
    try {
      const formData = new FormData();
      formData.append('varianttexture', selectedFiles[0]);
      const response = await variantUploadTextureModal(formData, store.id, product.id, variantId);
      if (response) {
        toast.success(response.message);
        await getProduct();
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleShowRemoveThreeDModel = () => {
    setShowRemoveThreeDModal(!showRemoveThreeDModal);
  };

  const handleThreeDModel = async (option, productVariantId) => {
    setSelectedOption(option);
    setSelectedVariant(productVariantId);
    handleShowRemoveThreeDModel();
  };

  const handleCategoryChange = async () => {
    try {
      const response = await mapProductCategory({
        store_id: store.id,
        product_ids: [product.id],
        category_ids: selectedCategories,
      });
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const onCategoryChange = (currentNode, selectedNodes) => {
    const selectedIds = selectedNodes.map((node) => node.id);
    setSelectedCategories(selectedIds);
    // console.log('onChange::', currentNode, selectedNodes);
  };

  const getStoreCategories = async (parentId, status) => {
    let foundCategories = [];
    try {
      const response = await storeMarketCategoryListByParent(store.id, parentId, status);
      if (response.success) {
        foundCategories = response.data;
      } else {
        foundCategories = [];
      }
    } catch (error) {
      foundCategories = [];
    }
    return foundCategories;
  };

  const getCategoryTreeFromParentView = async (parentId, status) => {
    const categoryArr = [];
    const existCategories = await getStoreCategories(parentId, status);
    await Promise.all(existCategories.map(async (eachCategory) => {
      const category = eachCategory;
      // eslint-disable-next-line no-await-in-loop
      const subCategory = await getCategoryTreeFromParentView(eachCategory.id, status);
      if (subCategory && subCategory !== '') {
        if (subCategory.length > 0) {
          category.children = subCategory;
        } else {
          category.children = [];
        }
        category.label = eachCategory.name;
        category.value = eachCategory.id;
        category.checked = selectedCategories.includes(eachCategory.id);
        categoryArr.push(category);
      }
    }));
    return categoryArr;
  };

  const getCategories = async () => {
    const categoriesData = await getCategoryTreeFromParentView(0, true);
    setCategories(categoriesData);
  };

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [product]);

  useEffect(() => {
    (async () => {
      await getProduct();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [productId]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="productDetail">
        <div className="px-3 mx-3 mx_0 pt-5">
          <h1 className="title mb-2 d-flex align-items-center mb-4">
            <Link to={'/merchant/product/list'} className="backArrow">
            <i className="bi bi-arrow-left-short"></i></Link>
            {product.name}
            {renderProductStatus()}
          </h1>
          <Row>
            <Col xs={12} md={8}>
              <Card className="p-3">
                <Form>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <Form.Label>Title</Form.Label>
                    <Form.Control type="text" value={product.name} readOnly disabled />
                  </Form.Group>
                  <Form.Group className="mb-3" controlId="formBasicPassword">
                    <Form.Label>Description</Form.Label>
                    <Form.Control as="textarea" rows={5} value={product.description} readOnly disabled />
                  </Form.Group>
                </Form>
              </Card>
              <Card className="p-3 my-3">
                <Row>
                  <Col className="mb_3" xs={12} md={5}>
                    <h3 className="fs-6">3D-Model</h3>
                    <p className="text-muted text13 mb-3">Supported 3D-formats are "glb" or "gltf". Maximum file size is 20MB</p>
                    {!product.ThreeDModel?.file_url
                      && (<div className="dropzone">
                      <Form.Group className="d-flex flex-column align-items-center">
                        <Form.Control type="file" accept="model/*" onChange={(e) => handleUploadThreeDModel(e.target.files)} />
                        <Button variant="primary">Add file</Button>
                        <Form.Text className="text-muted">Accepts .glb and .gltf</Form.Text>
                      </Form.Group>
                    </div>)}
                    {product.ThreeDModel?.file_url
                      && (<div className="poster">
                        <model-viewer alt="3D-Model" src={product.ThreeDModel?.file_url} ar poster={uploadedTDModelImg} shadow-intensity={1} camera-controls touch-action="pan-y" style={{ width: '100%', height: '100%' }} />
                        <Button onClick={() => handleThreeDModel(1, product.id)}><i className="bi bi-trash"></i></Button>
                      </div>)}
                  </Col>
                  <Col xs={12} md={7}>
                    <h3 className="fs-6">Hotspots</h3>
                    <p className="text-muted text13 mb-3">If your 3D Model supports VCOM Hotspots, add hotspot descriptions in the order that they been programed.</p>
                    <ListGroup as="ol" numbered>
                    {product.ProductAnchorDescription && product.ProductAnchorDescription.map((productAnchorDesc) => (
                      <ListGroup.Item key={productAnchorDesc.id} as="li">{productAnchorDesc.description} <ButtonGroup aria-label="Basic example"><Button onClick={() => handleShowAnchorModal(productAnchorDesc, 'edit')} variant="link"><i className="bi bi-pencil-fill"></i></Button><Button onClick={() => handleShowAnchorModal(productAnchorDesc, 'remove')} variant="link"><i className="bi bi-trash"></i></Button></ButtonGroup></ListGroup.Item>
                    ))}
                    </ListGroup>
                    <div>
                      <Button type="button" className="p-0 bg-transparent border-0 addBtn blueColor" onClick={handleShowAddAnchorModal}>+ Add New</Button>
                    </div>
                  </Col>
                </Row>
              </Card>
              <Card className="variants">
                <div className="p-3 border-bottom">
                  <h3 className="fs-6">Variants</h3>
                  <p className="text-muted text13 mb-0">Add texture tiles on your product variants. Supported file formats "glb" or "gltf". Maximum texture file size is 10MB.</p>
                </div>
                <Table>
                  <tbody>
                    {product.ProductVariant && product.ProductVariant.map((productVariant) => (
                      <tr key={productVariant.id}>
                        <td>
                          <div className="uploadImg d-flex align-items-center">
                            {!productVariant.TextureMedia?.file_url && (<div>
                              <Form.Group className="upload me-2">
                                <Form.Control type="file" accept="model/*" onChange={(e) => handleUploadTextureModel(e.target.files, productVariant.id)} />
                                <Image src={noImageIconImg} width={70} alt="VI" />
                              </Form.Group>
                            </div>)}
                            {productVariant.TextureMedia?.file_url && (<div className="posterVariant">
                              <model-viewer alt="3D-Model" src={productVariant.TextureMedia?.file_url} ar poster={uploadedTDModelImg} shadow-intensity={1} camera-controls touch-action="pan-y" style={{ width: '100%', height: '100%' }} />
                              <Button onClick={() => handleThreeDModel(2, productVariant.id)}><i className="bi bi-trash"></i></Button>
                            </div>)}
                            <Form.Text className="text-black text13 fw-500">{productVariant.name}<span>{productVariant?.sku}</span></Form.Text>
                          </div>
                        </td>
                        <td>{productVariant.sales_price} US$</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Card>
            </Col>
            <Col xs={12} md={4}>
              <Card>
                <div className="p-3 border-bottom">
                  <Form.Group>
                    <Form.Label>Product Status</Form.Label>
                    <Form.Select
                      className="form-select text14"
                      value={product.product_status}
                      onChange={(e) => handleProductStatus(e.target.value)}
                    >
                      <option value="">Select Status</option>
                      <option value={1}>Draft</option>
                      <option value={2}>Active</option>
                      <option value={3}>Archived</option>
                    </Form.Select>
                  </Form.Group>
                </div>
                <div className="p-3 border-bottom schedule">
                  <Form.Group>
                    <Form.Label>Schedule visibility</Form.Label>
                  </Form.Group>
                  <Row>
                    <Form.Group className="col-md-6 pe-0">
                      <Form.Control type="date" value={scheduleDate} onChange={(e) => setScheduleDate(e.target.value)} onBlur={() => handleScheduleDateTime()} />
                    </Form.Group>
                    <Form.Group className="col-md-6 ps-0">
                      <Form.Control type="time" value={scheduleTime} onChange={(e) => setScheduleTime(e.target.value)} onBlur={() => handleScheduleDateTime()} />
                    </Form.Group>
                  </Row>
                </div>
                <div className="p-3">
                  <Form.Group>
                    <Form.Label>Set Category</Form.Label>
                    <DropdownTreeSelect
                      texts={{ placeholder: 'Search Category' }}
                      data={categories}
                      className="choose-category"
                      onChange={onCategoryChange}
                      onBlur={handleCategoryChange}
                      searchPredicate={(node, searchTerm) => node.label && node.label.toLower().indexOf(searchTerm) >= 0}
                      mode='multiSelect'
                      showDropdown="default"
                      showPartiallySelected={true}
                      keepTreeOnSearch={true}
                      keepChildrenOnSearch={true}
                      keepOpenOnSelect={true}
                      clearSearchOnChange={true}
                    />
                  </Form.Group>
                </div>
              </Card>
              <Card className="p-3 mt-3 thumbnailCards">
                <h3 className="fs-6">Images</h3>
                {product?.Media
                && product.Media.map((productMedia, i) => (
                <Image key={productMedia.id} src={productMedia?.file_url} thumbnail width={i === 0 ? 100 : 50} />
                ))}
              </Card>
            </Col>
          </Row>
        </div>
      </div>
      <ToastContainer />
      <AddAnchorModal
        productId={product.id}
        getProduct={getProduct}
        showAddAnchorModal={showAddAnchorModal}
        handleShowAddAnchorModal={handleShowAddAnchorModal}
      />
      <EditAnchorModal
        selectedAnchor={selectedAnchor}
        getProduct={getProduct}
        showEditAnchorModal={showEditAnchorModal}
        handleShowEditAnchorModal={handleShowEditAnchorModal}
      />
      <RemoveAnchorModal
        selectedAnchor={selectedAnchor}
        getProduct={getProduct}
        showRemoveAnchorModal={showRemoveAnchorModal}
        handleShowRemoveAnchorModal={handleShowRemoveAnchorModal}
      />
      <ProductStatusAlertModal
        product={product}
        showSatusAlert={showStatusAlert}
        handleShowStatusAlert={handleShowStatusAlert}
      />
      <RemoveThreeDModal
        selectedOption={selectedOption}
        selectedVariant={selectedVariant}
        showRemoveThreeDModal={showRemoveThreeDModal}
        handleShowRemoveThreeDModel={handleShowRemoveThreeDModel}
        getProduct={getProduct}
      />
    </>
  );
};

export default ProductDetail;
