const marketList = async (storeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storemarketlist?store_id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productList = async (storeId = null, productStatus = null) => new Promise((resolve, reject) => {
  (async () => {
    try {
      let url = `${process.env.REACT_APP_API_FUNC_URL}/productlist?store_id=${storeId}`;
      if (productStatus !== null) {
        url = `${process.env.REACT_APP_API_FUNC_URL}/productlist?store_id=${storeId}&product_status=${productStatus}`;
      }
      const response = await fetch(url);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productDetail = async (productId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productdetail?id=${productId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productStatusUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productstatusupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const shopifyProductCreate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productshopifycreate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productAnchorDescriptionCreate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productanchordescriptioncreate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productAnchorDescriptionUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productanchordescriptionupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productAnchorDescriptionDelete = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productanchordescriptiondelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productUploadThreeDModal = async (data, storeId, productId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/ProductUploadThreeDModal?store_id=${storeId}&product_id=${productId}`, {
        method: 'POST',
        body: data,
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const productRemoveThreeDModal = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productthreedmodaldelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const variantUploadTextureModal = async (data, storeId, productId, variantId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/VariantUploadTextureModel?store_id=${storeId}&product_id=${productId}&variant_id=${variantId}`, {
        method: 'POST',
        body: data,
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const variantRemoveTextureModal = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/varianttexturemodaldelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeProductList = async (storeId = null, cursor = null, searchStr = null, cursorType = 2) => new Promise((resolve, reject) => {
  (async () => {
    try {
      let url = `${process.env.REACT_APP_API_FUNC_URL}/storeproductsearchlist?store_id=${storeId}&search_str=${searchStr}&cursor_type=${cursorType}`;
      if (cursor !== null) {
        url = `${process.env.REACT_APP_API_FUNC_URL}/storeproductsearchlist?store_id=${storeId}&cursor=${cursor}&search_str=${searchStr}&cursor_type=${cursorType}`;
      }
      const response = await fetch(url);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeVendorList = async (storeId = null) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storevendorlist?store_id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeProductTypeList = async (storeId = null) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storeproducttypelist?store_id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeProductCount = async (storeId = null) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storeproductcount?store_id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeTypeList = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storetypelist`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeMarketCategoryListByParent = async (storeId = null, parentId = 0, status = true) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/categorymarketbyparentlist?store_id=${storeId}&status=${status}&parent_id=${parentId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const categoryCreate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/categorymarketcreate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const categoryUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/categorymarketupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const categoryRemove = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/categorymarketdelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const mapProductCategory = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/productcategorymap`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

export {
  marketList,
  productList,
  productDetail,
  productUpdate,
  productStatusUpdate,
  shopifyProductCreate,
  productAnchorDescriptionCreate,
  productAnchorDescriptionUpdate,
  productAnchorDescriptionDelete,
  productUploadThreeDModal,
  productRemoveThreeDModal,
  variantUploadTextureModal,
  variantRemoveTextureModal,
  storeProductList,
  storeVendorList,
  storeProductTypeList,
  storeProductCount,
  storeTypeList,
  storeMarketCategoryListByParent,
  categoryCreate,
  categoryUpdate,
  categoryRemove,
  mapProductCategory,
};
