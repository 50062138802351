import { useState } from 'react';
import { Image } from 'react-bootstrap';
import File from './File';
import folderIcon from '../../../assets/images/virtual/folderIcon.png';

export default function Folder({ item, handleShow }) {
  const [isExpanded, setIsExpanded] = useState(false);

  const handleIsExpanded = (clickedItem) => {
    handleShow(1, clickedItem);
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <p
        key={`folder_${item.id}`}
        style={{
          cursor: 'pointer',
        }}
        onClick={() => handleIsExpanded(item)}
      >
        <Image src={folderIcon} width={16} height={16} alt='folder' /> {item.name}
      </p>
      {isExpanded && item.items && item.items.length > 0 && (<p
        className="expandedOver"
        key={item.id}
      >
        {item.items.map((eachItem) => {
          let returnItem = '';
          if (eachItem.isFolder) {
            returnItem = <Folder key={`folder_${eachItem.id}`} item={eachItem} handleShow={handleShow} />;
          } else {
            returnItem = <File key={eachItem.id} item={eachItem} />;
          }
          return returnItem;
        })}
      </p>)}
    </>
  );
}
