import {
  Row,
  Col,
  Image,
  Card,
  Button,
} from 'react-bootstrap';
import userIcon from '../../../assets/images/userIcon.png';
import phoneIcon from '../../../assets/images/phoneIcon.png';
import emailIcon from '../../../assets/images/emailIcon.png';
import billingIcon from '../../../assets/images/billingIcon.png';

const ContactInformation = ({ store, handleContactInfo }) => (
  <Card>
    <div className="cardTitle">
      <Row>
        <Col>
          <h3 className="fs-6 mb-1">Contact Information</h3>
          <p className="text-muted mb-0 text13">Used for VCOM billing and contacts about store and system information.</p>
        </Col>
        <Col className="text-end"><Button onClick={handleContactInfo} className="edit btn-link p-0 bg-transparent border-0">Edit</Button></Col>
      </Row>
    </div>
    <div className="cardStore">
      <Row>
        <Col>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={userIcon} alt="userIcon" />
            <div className="basicText">
              <span className="text-muted text13">Name</span>
              <p className="mb-0">{store.first_name || '-'} {store.last_name}</p>
            </div>
          </div>
        </Col>
        <Col>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={phoneIcon} alt="phoneIcon" />
            <div className="basicText">
              <span className="text-muted text13">Phone</span>
              <p className="mb-0">{store.phone || '-'}</p>
            </div>
          </div>
        </Col>
      </Row>
      <Row className="pt-3">
        <Col>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={emailIcon} alt="emailIcon" />
            <div className="basicText">
              <span className="text-muted text13">Email</span>
              <h6>{store.email || '-'}</h6>
            </div>
          </div>
        </Col>
        <Col>
          <div className="icon-with-text d-flex align-items-center gap12">
            <Image src={billingIcon} alt="billingIcon" />
            <div className="basicText">
              <span className="text-muted text13">Billing Address</span>
              <h6>{store.address_street}, {store.address_apartment}<br />
                {store.address_city}<br />{store.Country?.name}<br />{store.address_postalcode}
              </h6>
            </div>
          </div>
        </Col>
      </Row>
    </div>
  </Card>
);

export default ContactInformation;
