const keyCharge = 'auth.charge';

const setChargeSession = (chargeId) => {
  localStorage.setItem(keyCharge, chargeId);
};

const getChargeSession = () => {
  const chargeId = localStorage.getItem(keyCharge);

  return chargeId;
};

const cardList = async (tenantId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/cardlist?tenant_id=${tenantId}`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const cardCreate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/cardcreate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const cardUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/cardupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const cardRemove = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/carddelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const planList = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/planlist`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const planDetail = async (planId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/plandetail?id=${planId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const chargeDetail = async (chargeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/chargedetail?id=${chargeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const chargeCreate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/chargecreate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const chargeUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/chargeupdate`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const chargeRemove = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/chargedelete`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const billingHistory = async (storeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/chargelist?store_id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

export {
  cardList,
  cardCreate,
  cardUpdate,
  cardRemove,
  planList,
  planDetail,
  chargeDetail,
  chargeCreate,
  chargeUpdate,
  chargeRemove,
  billingHistory,
  setChargeSession,
  getChargeSession,
};
