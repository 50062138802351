import { Helmet } from 'react-helmet';
import { Card } from 'react-bootstrap';
import { ToastContainer } from 'react-toastify';

import './productsync.css';
import StoreProductList from '../../../components/merchant/product/StoreProductList';

const ProductSync = () => {
  const title = 'VCOM Merchant: Products';

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="productSync syncTable">
        <div className="px-3 mx-5 mx_3 m_0 mx_0  pt-5">
          <h1 className="title mb-2">{title}</h1>
          <p className="text-muted mb-2">Import products from you connected online store and make them available in your VR Store. After import,
           changes made to products in your online store will automatically be updated.</p>
          <span className="text13 text-muted"><i className="bi bi-info-circle-fill infoColor me-1"></i>Changes made to products in the VCOM Merchant Portal will not affect any information in your online store.</span>
          <Card className="mt-3 py-3">
            <StoreProductList />
          </Card>
        </div>
      </div>
      <ToastContainer />
    </>
  );
};

export default ProductSync;
