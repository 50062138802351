import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { getStoreSession, storeUpdate } from '../../../services/StoreService';

const ConfirmPreview = ({
  vrStoreStatus, showConfirmPreview, handleShowConfirmPreview, getStoreDetail,
}) => {
  const [show, setShow] = useState(false);
  const [storeStatus, setStoreStatus] = useState(vrStoreStatus);

  const handleConfirmPreview = async () => {
    if (storeStatus) {
      const store = getStoreSession();
      try {
        const response = await storeUpdate({ store_id: store.id, vr_store_status: storeStatus });
        if (response.success) {
          await getStoreDetail();
          toast.success('VR Store status set successfully.');
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error('Some error occured. Please try again later.');
    }
    handleShowConfirmPreview();
  };

  useEffect(() => {
    setShow(showConfirmPreview);
  }, [showConfirmPreview]);

  useEffect(() => {
    setStoreStatus(vrStoreStatus);
  }, [vrStoreStatus]);

  return (
    <>
      <Modal show={show} onHide={handleShowConfirmPreview}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Preview Mode</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will set the VR Store unavailable for the market and customers. Are you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button type="button" className="cancel" variant="primary" onClick={handleShowConfirmPreview}>
            No
          </Button>
          <Button type="button" variant="primary" onClick={handleConfirmPreview}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ConfirmPreview;
