import { useEffect, useState } from 'react';
import {
  Col, Row, Image,
} from 'react-bootstrap';

const ContentList = ({ dragUrl, contentSelected }) => {
  const [folderCrumb, setFolderCrumb] = useState([]);
  const [showContentData, setShowContentData] = useState(false);
  const [contentImages, setContentImages] = useState([]);

  const importAll = (r) => {
    // const images = {};
    // r.keys().forEach((item, index) => { images[item.replace('./', '')] = r(item); });
    // return images;
    const images = [];
    r.keys().forEach((item, index) => { images.push(r(item)); });
    return images;
  };

  const getContentImages = () => {
    const images = importAll(require.context('../../../assets/images/virtual/content/themecollections/displaystands', false, /\.(png|jpe?g|svg)$/));
    setContentImages(images);
  };

  // const getBreadCrumbs = () => {
  //   let bredcrumbData = '';
  //   if (folderCrumb && folderCrumb.length > 0) {
  //     folderCrumb.forEach((eachCrumb, i) => {
  //       console.log(eachCrumb);
  //       bredcrumbData += eachCrumb;
  //       if (i !== (folderCrumb.length - 1)) {
  //         bredcrumbData += ' / ';
  //       }
  //     });
  //   }
  //   return bredcrumbData;
  // };

  useEffect(() => {
    if (contentSelected && contentSelected?.name) {
      folderCrumb.push(contentSelected.name);
      setFolderCrumb(folderCrumb);
      if (contentSelected.name === 'Display Stands' || contentSelected.name === 'Other stuff') {
        getContentImages();
        setShowContentData(true);
      } else {
        setContentImages([]);
        setShowContentData(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contentSelected]);

  return (
    <>
      <Row>
        <Col xs={12} md={12}>
          <div className="d-flex align-items-center mb_2 py-2 myProduct">
            <h6 className="text-white mb-0 me-2"> &nbsp; </h6>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12}>
          <div className="contentImage_holder">
            <div className='folderCrumb'>
              <ol
                style={{
                  listStyleType: 'none',
                  padding: 0,
                  margin: 0,
                  display: 'flex',
                }}
              >
              {folderCrumb && folderCrumb.length > 0 && folderCrumb.map((item, index) => (
                <li key={`bread_${index}`}>
                  <span>
                    {item}
                  </span>
                  {index < folderCrumb.length - 1 && (
                    <span style={{ margin: '0 8px' }}>/</span>
                  )}
                </li>
              ))}
            </ol>
            </div>
            <div className="contentbox">
              {showContentData && contentImages && contentImages.map((contentImage, i) => (
                <div className='content_thumbnails' key={`img_${i}`}>
                  <Image
                    src={contentImage}
                    thumbnail
                    alt="image"
                    draggable={true}
                    onDragStart={(e) => {
                      // eslint-disable-next-line
                      dragUrl.current = e.target.src;
                    }}
                    // style={{ width: '100px', height: '100px', margin: '5px' }}
                  />
                  <p>Display Stand.glb</p>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
    </>
  );
};

export default ContentList;
