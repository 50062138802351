import { useEffect, useState } from 'react';
import {
  Button, Modal, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { cardRemove } from '../../../services/BillingService';

const RemovePaymentMethod = ({
  selectedCard,
  getCards,
  showRemovePaymentMethod,
  handleShowRemovePaymentMethod,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemovePaymentMethod = async () => {
    if (selectedCard) {
      setIsLoading(true);
      try {
        const response = await cardRemove({ id: selectedCard.id });
        if (response.success) {
          toast.success(response.message);
          await getCards();
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error('Some error occured. Please try again later.');
      }
      setIsLoading(false);
    } else {
      toast.error('Please enter all the required details.');
    }
    handleShowRemovePaymentMethod();
  };

  useEffect(() => {
    setShow(showRemovePaymentMethod);
  }, [showRemovePaymentMethod]);

  return (
    <>
      <Modal show={show} onHide={handleShowRemovePaymentMethod}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Delete Payment Method</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>This will delete selected payment method. Are you sure?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowRemovePaymentMethod}>
            No
          </Button>
          <Button variant="primary" onClick={handleRemovePaymentMethod} disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Yes</span>
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default RemovePaymentMethod;
