import { Helmet } from 'react-helmet';
import { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import {
  Row,
} from 'react-bootstrap';
import {
  Stage, Layer, Image,
} from 'react-konva';
import useImage from 'use-image';

import './solutionedit.css';
// import canvasImage from '../../../assets/images/canvasImage.jpg';
import ProductList from '../../../components/virtual/solutionedit/ProductList';
import MyAssetLibrary from '../../../components/virtual/solutionedit/MyAssetLibrary';
import ObjectList from '../../../components/virtual/solutionedit/ObjectList';
import PropertyList from '../../../components/virtual/solutionedit/PropertyList';
import { solutionDetail } from '../../../services/SolutionService';
import AppList from '../../../components/virtual/solutionedit/AppList';
import ContentList from '../../../components/virtual/solutionedit/ContentList';

const SolutionEdit = () => {
  const title = 'Solution Editor';
  // eslint-disable-next-line no-unused-vars
  const [solution, setSolution] = useState({});
  const { solutionId } = useParams();
  const [showContent, setShowContent] = useState(true);
  const [contentSelected, setContentSelected] = useState({});
  const [showProduct, setShowProduct] = useState(false);
  const [showApp, setShowApp] = useState(false);
  const parentDivRef = useRef(null);
  const [dimensions, setDimensions] = useState({
    width: 0,
    height: 0,
  });

  const dragUrl = useRef();
  const stageRef = useRef();
  const [objects, setObjects] = useState([]);

  const handleShow = (option, data) => {
    setContentSelected(data);
    if (option === 1) {
      setShowContent(true);
      setShowProduct(false);
      setShowApp(false);
    } else if (option === 2) {
      setShowContent(false);
      setShowProduct(true);
      setShowApp(false);
      // setContentSelected({});
    } else if (option === 3) {
      setShowContent(false);
      setShowProduct(false);
      setShowApp(true);
      // setContentSelected({});
    }
  };

  // const handleDragStart = (e) => {
  //   const id = e.target.id();
  //   setObjects(
  //     // eslint-disable-next-line
  //     objects.map((object) => {
  //       return {
  //         ...object,
  //         isDragging: object.id === id,
  //       };
  //     }),
  //   );
  // };

  // const handleDragEnd = (e) => {
  //   setStars(
  //     // eslint-disable-next-line
  //     objects.map((object) => {
  //       return {
  //         ...object,
  //         isDragging: false,
  //       };
  //     }),
  //   );
  // };

  const getSolution = async () => {
    try {
      const response = await solutionDetail(solutionId);
      if (response.success) {
        setSolution(response.data);
      } else {
        setSolution({});
      }
    } catch (error) {
      setSolution({});
    }
  };

  // const BackImage = () => {
  //   const [image] = useImage(canvasImage);
  //   return <Image image={image} width={1482} height={450} />;
  // };

  const URLImage = ({ image }) => {
    const [img] = useImage(image.src);
    return (
      <Image
        image={img}
        width={100}
        height={100}
        x={image.x}
        y={image.y}
        // I will use offset to set origin to the center of the image
        offsetX={img ? 100 / 2 : 0}
        offsetY={img ? 100 / 2 : 0}
        draggable={true}
      />
    );
  };

  useEffect(() => {
    (async () => {
      await getSolution();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [solutionId]);

  useEffect(() => {
    if (parentDivRef.current?.offsetHeight && parentDivRef.current?.offsetWidth) {
      setDimensions({
        width: parentDivRef.current.offsetWidth,
        height: parentDivRef.current.offsetHeight,
      });
    }
  }, [parentDivRef.current?.offsetWidth, parentDivRef.current?.offsetHeight]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="virtualAssets">
        <Row className="m-0">
          <div className="p-0 virtualCanvasArea">
            <div className="asstesCount">
              <p className="mb-0 text-end text-white fs-12">FPS: 127ms</p>
            </div>
            <div className="canvasFrame"
              onDrop={(e) => {
                e.preventDefault();
                // register event position
                stageRef.current.setPointersPositions(e);
                // add image
                setObjects(
                  objects.concat([
                    {
                      ...stageRef.current.getPointerPosition(),
                      src: dragUrl.current,
                    },
                  ]),
                );
              }}
              onDragOver={(e) => e.preventDefault()}
              ref={parentDivRef}
            >
              <Stage width={dimensions.width} height={dimensions.height} ref={stageRef}>
                <Layer>
                  {/* <BackImage /> */}
                  {objects.map((object, i) => <URLImage key={i} image={object} />)}
                </Layer>
              </Stage>
            </div>
            <div className="myAssets">
              <div className="searchProduct">
                <Row>
                  <div className="pe-0 leftAssets">
                    <MyAssetLibrary handleShow={handleShow} setContentSelected={setContentSelected} />
                  </div>
                  <div className="ps-0 rightAssets">
                    {showContent && <ContentList dragUrl={dragUrl} contentSelected={contentSelected} />}
                    {showProduct && <ProductList dragUrl={dragUrl} />}
                    {showApp && <AppList />}
                  </div>
                </Row>
              </div>
            </div>
          </div>
          <div className="p-0 sidebarCanvas">
            <div className="topSideBar">
              <PropertyList />
            </div>
            <div className="bottomSideBar">
              <ObjectList />
            </div>
          </div>
        </Row>
      </div>
    </>
  );
};

export default SolutionEdit;
