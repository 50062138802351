import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Footer from '../components/Footer';
import MerchantNavbar from '../components/merchant/MerchantNavbar';
import VirtualSidebar from '../components/virtual/VirtualSidebar';

import useAuth from '../hooks/useAuth';

function VirtualLayout() {
  const auth = useAuth();
  const { pathname } = useLocation();

  if (auth.isAuth()) {
    return (
      <>
        <MerchantNavbar />
        <div className="container-fluid">
          <div className="row">
            <nav id="sidebarMenu" className="d-md-block vartualSidebar collapse">
              <VirtualSidebar />
            </nav>
            <main className="virtualMain ms-sm-auto px-md-4">
              <Outlet />
              <Footer />
            </main>
          </div>
        </div>
      </>
    );
  }

  return <Navigate to={`/auth/login?redirect=${encodeURIComponent(pathname)}`} replace />;
}

export default VirtualLayout;
