import {
  useEffect, useState, useCallback, useMemo,
} from 'react';
import {
  Row, Col, Form, InputGroup, ButtonGroup, Button, Image, Badge, Dropdown, DropdownButton,
} from 'react-bootstrap';
import DataTable from 'react-data-table-component';
import { getStoreSession } from '../../../services/StoreService';
import {
  storeVendorList, storeProductList, storeProductCount, storeProductTypeList,
} from '../../../services/ProductService';
import noImageIconImg from '../../../assets/images/no-image-icon.png';
import ImportProductModal from './ImportProductModal';

const StoreProductList = () => {
  const [products, setProducts] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [productTypes, setProductTypes] = useState([]);
  const [totalProduct, setTotalProduct] = useState(0);
  const [totalNotSyncProduct, setTotalNotSyncProduct] = useState(0);
  const [searchStr, setSearchStr] = useState('status:active,draft');
  const [syncedProduct, setSyncedProduct] = useState([]);
  const [previous, setPrevious] = useState('');
  const [next, setNext] = useState('');
  const [cursorType, setCursorType] = useState(2);
  const [selectedCursor, setSelectedCursor] = useState('');
  const [selectedSearchTxt, setSelectedSearchTxt] = useState('');
  const [selectedVendor, setSelectedVendor] = useState('');
  const [selectedProductType, setSelectedProductType] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [toggleCleared, setToggleCleared] = useState(false);
  const [tableClass, setTableClass] = useState('');
  const [isSelectAllNotSyncProduct, setIsSelectAllNotSyncProduct] = useState(false);
  const [showImportProductModal, setShowImportProductModal] = useState(false);

  const columns = [
    {
      name: 'Product',
      selector: (row) => <><Image src={row.node?.featuredImage ? row.node?.featuredImage?.transformedSrc : noImageIconImg} thumbnail rounded alt='product' width={50} height={50} /> {row.node?.title}</>,
      width: '35%',
    },
    {
      name: 'Type',
      selector: (row) => row.node?.productType,
    },
    {
      name: 'Inventory',
      selector: (row) => `${row.node?.totalInventory} in stock for ${row.node?.totalVariants} variants`,
    },
    {
      name: 'Status',
      selector: (row) => getProductStatusBadge(syncedProduct.includes(row.node?.id.replace('gid://shopify/Product/', ''))),
      width: '10%',
    },
    {
      name: 'Vendor',
      selector: (row) => row.node?.vendor,
    },
  ];

  const handleRowSelected = useCallback((state) => {
    const selectedIds = state.selectedRows.map((row) => row.node.id.replace('gid://shopify/Product/', ''));
    setSelectedRows(selectedIds);
    if (selectedIds.length > 0) {
      setTableClass('product-table');
    } else {
      setTableClass('');
    }
  }, []);

  const handleShowImportProductModal = () => {
    setShowImportProductModal(!showImportProductModal);
  };

  const handleToggleCleared = () => {
    setTableClass('');
    setToggleCleared(!toggleCleared);
  };

  const contextActions = useMemo(() => {
    const handleProductImport = () => {
      handleShowImportProductModal();
    };

    return (
      <>
        <div className="selectAll">
          <Form.Check
            type="checkbox"
            id='default-checkbox'
            label={`Select All (${totalNotSyncProduct})`}
            onChange={(e) => setIsSelectAllNotSyncProduct(e.target.checked)}
          />
          <DropdownButton key="moreaction" title="More Action">
            <Dropdown.Item as="button" onClick={handleProductImport}>Import</Dropdown.Item>
          </DropdownButton>
        </div>
      </>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalNotSyncProduct]);

  const getVendors = async () => {
    try {
      const store = getStoreSession();
      if (store) {
        const response = await storeVendorList(store.id);
        if (response.success) {
          setVendors(response.data);
        } else {
          setVendors([]);
        }
      }
    } catch (error) {
      setVendors([]);
    }
  };

  const getProductTypes = async () => {
    try {
      const store = getStoreSession();
      if (store) {
        const response = await storeProductTypeList(store.id);
        if (response.success) {
          setProductTypes(response.data);
        } else {
          setProductTypes([]);
        }
      }
    } catch (error) {
      setProductTypes([]);
    }
  };

  const getProductCount = async () => {
    try {
      const store = getStoreSession();
      if (store) {
        const response = await storeProductCount(store.id);
        if (response.success) {
          setTotalProduct(response.data);
        } else {
          setTotalProduct(0);
        }
      }
    } catch (error) {
      setTotalProduct(0);
    }
  };

  const getProducts = async () => {
    try {
      const store = getStoreSession();
      if (store) {
        const response = await storeProductList(store.id, selectedCursor, searchStr, cursorType);
        if (response.success) {
          const productData = response.data;
          const pageInfo = productData.products?.products?.pageInfo;
          const productArr = productData.products?.products?.edges;
          const syncProductArr = productData.synced_products.map((syncProduct) => syncProduct.shopify_product_id);
          setSyncedProduct(syncProductArr);
          // const notSyncedProductArr = productArr.filter((eachProduct) => !syncProductArr.includes(
          //   eachProduct.node.id.replace('gid://shopify/Product/', ''),
          // ));
          setProducts(productArr);
          const previousStr = pageInfo?.hasPreviousPage ? pageInfo.startCursor : '';
          setPrevious(previousStr);
          const nextStr = pageInfo?.hasNextPage ? pageInfo.endCursor : '';
          setNext(nextStr);
        } else {
          setProducts([]);
        }
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const getProductStatusBadge = (status) => {
    let badge = <Badge className="notsynced" bg='info'>Not Synced</Badge>;
    if (status) {
      badge = <Badge className="synced" bg='info'>Synced</Badge>;
    }
    return badge;
  };

  const handleSearch = (data) => {
    setSelectedSearchTxt(data);
    if (data) {
      setSearchStr(`status:active,draft title:${data}`);
    } else {
      setSearchStr('status:active,draft');
    }
  };

  const handleVendor = (data) => {
    setSelectedVendor(data);
    if (data) {
      setSearchStr(`status:active,draft vendor:${data}`);
    } else {
      setSearchStr('status:active,draft');
    }
  };

  const handleProductType = (data) => {
    setSelectedProductType(data);
    if (data) {
      setSearchStr(`status:active,draft product_type:${data}`);
    } else {
      setSearchStr('status:active,draft');
    }
  };

  const handleClear = () => {
    setSearchStr('status:active,draft');
    setSelectedSearchTxt('');
    setSelectedProductType('');
    setSelectedVendor('');
  };

  const handleNextPrevious = (cursor, cursorTp) => {
    setSelectedCursor(cursor);
    setCursorType(cursorTp);
  };

  const renderTableHeaderTitle = () => (
    <div className="searchBar">
      <Row>
        <Col sm={6}>
          <Form>
            <InputGroup>
              <InputGroup.Text id="basic-addon1"><i className="bi bi-search"></i></InputGroup.Text>
              <Form.Control
                placeholder="Enter Search"
                aria-describedby="basic-addon1"
                onChange={(e) => handleSearch(e.target.value)}
                value={selectedSearchTxt}
              />
            </InputGroup>
          </Form>
        </Col>
        <Col sm={6}>
          <ButtonGroup>
            <Form.Select onChange={(e) => handleProductType(e.target.value)} value={selectedProductType}>
              <option value="">Type</option>
              {productTypes
                && productTypes.map((productType) => (<option key={productType} value={productType}>
                  {productType}</option>))}
            </Form.Select>
            <Form.Select onChange={(e) => handleVendor(e.target.value)} value={selectedVendor}>
              <option value="">Vendor</option>
              {vendors && vendors.map((vendor) => <option key={vendor} value={vendor}>{vendor}</option>)}
            </Form.Select>
            <Button variant="outline-light" onClick={handleClear}>Clear</Button>
          </ButtonGroup>
        </Col>
      </Row>
    </div>
  );

  useEffect(() => {
    (async () => {
      await getVendors();
      await getProductTypes();
      await getProductCount();
      await getProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSearchTxt, selectedVendor, selectedProductType, selectedCursor]);

  useEffect(() => {
    if (totalProduct && syncedProduct) {
      const notSyncProduct = Number(totalProduct) - Number(syncedProduct.length);
      setTotalNotSyncProduct(notSyncProduct);
    }
  }, [totalProduct, syncedProduct]);

  return (
    <>
      <div className={tableClass}>
        <DataTable
          title={renderTableHeaderTitle()}
          columns={columns}
          data={products}
          selectableRows
          contextActions={contextActions}
          onSelectedRowsChange={handleRowSelected}
          clearSelectedRows={toggleCleared}
          keyField='node.id'
          responsive
          hover
        />
        {products && products.length > 0 && (<Row>
          <Col xs={12} className="mt-3 text-center paginations">
            {previous === '' && <Button variant="light" disabled>Previous</Button>}
            {previous !== '' && <Button variant="light" onClick={() => handleNextPrevious(previous, 1)}>Previous</Button>}
            {next === '' && <Button variant="light" disabled>Next</Button>}
            {next !== '' && <Button variant="light" onClick={() => handleNextPrevious(next, 2)}>Next</Button>}
          </Col>
        </Row>)}
      </div>
      <ImportProductModal
        isSelectAllNotSyncProduct={isSelectAllNotSyncProduct}
        selectedRows={selectedRows}
        showImportProductModal={showImportProductModal}
        handleShowImportProductModal={handleShowImportProductModal}
        handleToggleCleared={handleToggleCleared}
        getProducts={getProducts}
      />
    </>
  );
};

export default StoreProductList;
