import { useEffect, useState } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import './merchant-sidebar.css';

const MerchantSidebar = () => {
  const items = [
    {
      path: '/merchant',
      title: 'Home',
      icon: (<svg xmlns="http://www.w3.org/2000/svg" version="1.1" x="0px" y="0px" width="20px" height="20px" viewBox="0 0 50 50"><path d="M39.469,27.064L24.877,14.176c-0.378-0.334-0.946-0.334-1.325,0L8.961,27.064
    c-0.107,0.096-0.169,0.232-0.169,0.375V42.6c0,0.275,0.224,0.5,0.5,0.5h10.257c0.276,0,0.5-0.225,0.5-0.5v-9.684
    c0-1.104,0.896-2,2-2h4.332c1.104,0,2,0.896,2,2V42.6c0,0.275,0.224,0.5,0.5,0.5h10.256c0.276,0,0.5-0.225,0.5-0.5v-15.16
    C39.637,27.297,39.575,27.16,39.469,27.064"></path><path d="M43.488,22.064L26.855,7.446c-1.51-1.327-3.771-1.327-5.281,0L4.942,22.064
    c-0.821,0.721-0.861,1.984-0.089,2.756c0.705,0.705,1.837,0.741,2.585,0.081L22.893,11.29c0.756-0.666,1.888-0.666,2.644,0
    l15.454,13.611c0.749,0.66,1.881,0.624,2.586-0.081C44.35,24.048,44.309,22.784,43.488,22.064"></path></svg>),
      target: '_self',
    },
    {
      path: '/merchant/product/list',
      title: 'Product',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50 " focusable="false" aria-hidden="true" x="0px" y="0px" width="20px" height="20px"><path d="M44.056,16.54l-17.3,10.379c-0.177,0.105-0.285,0.298-0.285,0.504v19.334c0,0.455,0.49,0.738,0.883,0.511
        l17.299-9.988c0.181-0.106,0.294-0.302,0.294-0.51V17.044C44.946,16.586,44.448,16.305,44.056,16.54 M5.944,16.54
        c-0.392-0.235-0.89,0.047-0.89,0.505V36.77c0,0.208,0.111,0.403,0.294,0.51l17.301,9.988c0.392,0.228,0.882-0.056,0.882-0.511
        V27.423c0-0.206-0.108-0.398-0.286-0.504L5.944,16.54z M16.504,34.451l-0.876-0.471v2.955c0,0.396-0.321,0.546-0.715,0.332
        l-2.238-1.198c-0.396-0.214-0.718-0.705-0.718-1.104v-2.953l-0.875-0.472c-0.615-0.328-0.946-1.229-0.542-1.478l2.711-1.672
        c0.287-0.177,0.798,0.098,1.084,0.582l2.712,4.581C17.45,34.234,17.119,34.78,16.504,34.451"></path>
        <path d="M31.769,6.469L25.294,2.73c-0.182-0.104-0.407-0.104-0.588,0L7.194,12.841
        c-0.388,0.224-0.393,0.784-0.008,1.014l6.684,4.01c0.186,0.113,0.418,0.113,0.605,0L31.776,7.482
        C32.162,7.252,32.157,6.693,31.769,6.469 M43.451,12.862l-2.947-6.427c-0.147-0.317-0.537-0.438-0.838-0.258L22.153,16.684
        c-0.253,0.152-0.354,0.467-0.237,0.738l2.818,6.505c0.141,0.327,0.538,0.453,0.842,0.271l17.645-10.588
        C43.477,13.457,43.576,13.135,43.451,12.862"></path></svg>,
      target: '_self',
      childs: [
        {
          path: '/merchant/product/sync', title: 'Import Product', target: '_self',
        },
        {
          path: '/merchant/product/categories', title: 'Categories', target: '_self',
        },
      ],
    },
    {
      path: '/merchant/store/detail',
      title: 'Store Detail',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50 " focusable="false" aria-hidden="true" x="0px" y="0px" width="20px" height="20px"><path d="M43.777,40.142h-2.424V21.044c-0.115,0.008-0.229,0.02-0.346,0.02h-0.281c-2.072,0-3.926-0.951-5.148-2.44
        c-1.223,1.489-3.076,2.44-5.146,2.44h-0.283c-2.072,0-3.926-0.951-5.148-2.44c-1.223,1.489-3.077,2.44-5.148,2.44h-0.282
        c-2.071,0-3.925-0.951-5.148-2.44c-1.223,1.489-3.076,2.44-5.149,2.44H8.991c-0.116,0-0.229-0.012-0.344-0.02v19.098H6.224
        c-0.669,0-1.211,0.542-1.211,1.211v4.24c0,0.334,0.271,0.605,0.606,0.605h38.765c0.334,0,0.605-0.271,0.605-0.605v-4.24
        C44.988,40.684,44.443,40.142,43.777,40.142 M31.057,38.325c0,0.333-0.271,0.605-0.605,0.605H19.548
        c-0.334,0-0.605-0.272-0.605-0.605V26.21c0-0.333,0.271-0.604,0.605-0.604h10.903c0.334,0,0.605,0.271,0.605,0.604V38.325z"/>
        <path d="M44.643,12.584v-2.513c0-0.42-0.109-0.833-0.318-1.198l-2.191-3.849c-0.432-0.757-1.236-1.223-2.107-1.223
        H9.97c-0.871,0-1.674,0.466-2.105,1.223L5.674,8.873c-0.208,0.365-0.319,0.778-0.319,1.198v3.499h0.001v0.831
        c0,2.004,1.63,3.634,3.634,3.634h0.283c2.005,0,3.634-1.63,3.634-3.634v-2.12c0-0.837,0.678-1.515,1.515-1.515
        s1.514,0.678,1.514,1.515v2.12c0,2.004,1.63,3.634,3.634,3.634h0.282c2.003,0,3.634-1.63,3.634-3.634v-1.817h-0.029v-0.303
        c0-0.837,0.677-1.515,1.515-1.515c0.836,0,1.513,0.678,1.513,1.515v1.289h0.029v0.831c0,2.004,1.631,3.634,3.635,3.634h0.283
        c2.002,0,3.633-1.63,3.633-3.634v-2.12c0-0.837,0.678-1.515,1.514-1.515s1.514,0.678,1.514,1.515v2.12
        c0,2.004,1.631,3.634,3.635,3.634h0.281c2.004,0,3.635-1.63,3.635-3.634V12.584L44.643,12.584z"/></svg>,
      target: '_self',
    },
    {
      path: '/merchant/subscription/detail',
      title: 'Subscription',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" focusable="false" aria-hidden="true" x="0px" y="0px" width="20px" height="20px"><path d="M20.174,42.281L7.837,29.944c-0.657-0.657-1.722-0.657-2.379,0c-0.657,0.657-0.657,1.722,0,2.379
        l12.337,12.336c0.328,0.329,0.759,0.493,1.188,0.493c0.431,0,0.862-0.164,1.19-0.493C20.831,44.003,20.831,42.938,20.174,42.281"/><path d="M45.007,21.015L43.06,8.687c-0.151-0.959-0.905-1.713-1.864-1.866l-12.33-1.945
        c-0.707-0.113-1.428,0.12-1.935,0.629L8.706,23.729c-0.875,0.877-0.875,2.297,0,3.172L22.98,41.175
        c0.876,0.876,2.296,0.876,3.172,0l18.224-18.224C44.885,22.444,45.118,21.723,45.007,21.015 M38.559,14.733
        c-0.876,0.874-2.296,0.874-3.172,0c-0.876-0.876-0.876-2.296,0-3.173c0.876-0.876,2.296-0.876,3.172,0
        C39.435,12.437,39.435,13.857,38.559,14.733"/></svg>,
      target: '_self',
    },
    {
      path: '/merchant/billing',
      title: 'Billing',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50 " focusable="false" aria-hidden="true" x="0px" y="0px" width="20px" height="20px"><path d="M31.309,24.257c-5.119,0-9.27,4.15-9.27,9.27c0,5.116,4.15,9.269,9.27,9.269s9.269-4.152,9.269-9.269
        C40.577,28.407,36.428,24.257,31.309,24.257 M30.854,31.885l2.094,1.075c1.252,0.645,1.914,1.964,1.689,3.364
        c-0.174,1.088-0.858,1.948-1.812,2.363v0.28c0,0.709-0.577,1.287-1.288,1.287c-0.709,0-1.287-0.578-1.287-1.287v-0.196
        c-1.004-0.333-1.852-1.076-2.295-2.096c-0.283-0.651,0.016-1.41,0.667-1.691c0.654-0.284,1.413,0.014,1.695,0.668
        c0.214,0.49,0.709,0.778,1.245,0.737c0.402-0.061,0.502-0.289,0.532-0.472c0.017-0.109,0.044-0.477-0.325-0.668l-2.095-1.077
        c-1.264-0.649-1.935-2.029-1.672-3.432c0.229-1.23,1.088-2.153,2.247-2.466v-0.191c0-0.712,0.578-1.287,1.287-1.287
        c0.711,0,1.288,0.575,1.288,1.287v0.476c0.805,0.389,1.464,1.042,1.835,1.893c0.285,0.652-0.014,1.41-0.666,1.696
        c-0.654,0.282-1.409-0.017-1.695-0.668c-0.213-0.491-0.713-0.785-1.244-0.741c-0.387,0.041-0.49,0.314-0.521,0.476
        C30.509,31.352,30.486,31.693,30.854,31.885"/> <path d="M33.647,3.927L31.366,5.17c-0.308,0.168-0.679,0.168-0.987,0l-3.311-1.805
        c-0.307-0.167-0.679-0.167-0.986,0.001L22.779,5.17c-0.309,0.168-0.68,0.168-0.988,0l-3.302-1.803c-0.308-0.168-0.68-0.168-0.987,0
        L14.2,5.17c-0.308,0.168-0.68,0.168-0.987,0l-2.266-1.239c-0.687-0.376-1.524,0.121-1.524,0.903v33.004
        c0,0.781,0.836,1.278,1.522,0.903l2.281-1.243c0.308-0.168,0.68-0.168,0.986,0l3.312,1.805c0.308,0.169,0.68,0.169,0.986,0
        l1.94-1.058c-0.633-1.449-0.986-3.042-0.986-4.719c0-6.532,5.313-11.844,11.844-11.844c1.354,0,2.648,0.239,3.861,0.66V4.832
        C35.17,4.049,34.335,3.553,33.647,3.927 M16.116,28.939h-1.545c-0.711,0-1.287-0.578-1.287-1.287c0-0.712,0.576-1.287,1.287-1.287
        h1.545c0.712,0,1.287,0.575,1.287,1.287C17.403,28.361,16.828,28.939,16.116,28.939 M19.133,23.79h-4.562
        c-0.711,0-1.287-0.577-1.287-1.288c0-0.712,0.576-1.287,1.287-1.287h4.562c0.71,0,1.287,0.575,1.287,1.287
        C20.42,23.213,19.843,23.79,19.133,23.79 M29.77,18.641H14.571c-0.711,0-1.287-0.578-1.287-1.287c0-0.712,0.576-1.288,1.287-1.288
        H29.77c0.71,0,1.287,0.576,1.287,1.288C31.057,18.063,30.479,18.641,29.77,18.641 M29.77,13.491H14.571
        c-0.711,0-1.287-0.578-1.287-1.288c0-0.711,0.576-1.287,1.287-1.287H29.77c0.71,0,1.287,0.576,1.287,1.287
        C31.057,12.913,30.479,13.491,29.77,13.491"/></svg>,
      target: '_self',
    },
    {
      path: 'https://vcomverse.io/customer-support/',
      title: 'Support',
      icon: <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50 " focusable="false" aria-hidden="true" x="0px" y="0px" width="20px" height="20px"><path d="M38.936,17.404c-0.146,0-0.287,0.021-0.428,0.043c-1.49-6.203-6.98-10.827-13.526-10.827
        c-6.543,0-12.033,4.621-13.525,10.823c-0.128-0.02-0.258-0.04-0.393-0.04c-1.45,0-2.63,1.181-2.63,2.629v3.157
        c0,1.451,1.18,2.63,2.63,2.63c0.32,0,0.622-0.066,0.906-0.17c0.773,1.944,2.664,3.327,4.88,3.327c0.582,0,1.052-0.471,1.052-1.053
        c0-0.58-0.47-1.053-1.052-1.053c-1.74,0-3.156-1.415-3.156-3.155v-3.334c0.228-6.175,5.196-11.13,11.288-11.13
        c6.237,0,11.309,5.188,11.309,11.565c0,0.027,0.014,0.047,0.014,0.072v2.302c0,1.451,1.182,2.63,2.631,2.63
        c1.451,0,2.631-1.179,2.631-2.63v-3.157C41.566,18.585,40.387,17.404,38.936,17.404"/><path d="M33.615,32.119h-3.088c-0.686,0-1.332,0.336-1.726,0.896l-2.157,3.092
        c-0.845,1.209-2.638,1.197-3.466-0.023l-2.065-3.041c-0.392-0.578-1.044-0.924-1.742-0.924H16.34c-3.905,0-7.071,3.166-7.071,7.07
        v3.139c0,0.58,0.471,1.051,1.052,1.051h29.314c0.58,0,1.053-0.471,1.053-1.051v-3.139C40.688,35.285,37.52,32.119,33.615,32.119
         M24.981,29.49c4.79,0,8.675-3.884,8.675-8.674s-3.885-8.672-8.675-8.672c-4.789,0-8.672,3.883-8.672,8.672
        S20.193,29.49,24.981,29.49"/></svg>,
      target: '_blank',
    },
  ];
  const [showSubMenu, setShowSubMenu] = useState(false);
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split('/');

  useEffect(() => {
    if ((splitLocation[3] === 'sync') || (splitLocation[3] === 'categories')) {
      setShowSubMenu(true);
    } else {
      setShowSubMenu(false);
    }
    // eslint-disable-next-line
  }, [splitLocation]);

  return (
    <>
      <div className="position-sticky pt-5 mt-1">
        <ul className="nav flex-column">
          {items.map((item, i) => (
            <li key={i} className={`nav-item ${(item.childs && item.childs.length > 0) ? 'dropdown' : ''}`}>
              {item.childs && <NavLink className={`nav-link ${(splitLocation[1] === 'merchant') && (splitLocation[2] === 'product') ? 'active' : ''}`} to={item.path} target={item.target}>
                {item.icon}
                {item.title}
              </NavLink>}
              {!item.childs && <NavLink className="nav-link" end to={item.path} target={item.target}>
                {item.icon}
                {item.title}
              </NavLink>}
              {item.childs && (
                <>
                  <button className="dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" id="dropdownMenu">
                    <span className="sr-only"></span>
                  </button>
                  <ul className={`dropdown-menu sub-menu-list ${showSubMenu ? 'show' : ''}`} aria-labelledby="dropdownMenu">
                    {item.childs.map((child, j) => (
                      <li key={j} className="nav-item">
                        <NavLink className="nav-link" end to={child.path} target={child.target}>
                          {child.icon}
                          {child.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </>
  );
};

export default MerchantSidebar;
