import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import {
  Button, Card, Col, Form, Row,
} from 'react-bootstrap';
import Nestable from 'react-nestable';
import { ToastContainer } from 'react-toastify';

import './categorylist.css';
import { getStoreSession } from '../../../services/StoreService';
import { storeMarketCategoryListByParent } from '../../../services/ProductService';
import AddCategoryModal from '../../../components/merchant/category/AddCategoryModal';
import EditCategoryModal from '../../../components/merchant/category/EditCategoryModal';
import RemoveCategoryModal from '../../../components/merchant/category/RemoveCategoryModal';

const CategoryList = () => {
  const title = 'Category List';
  const [categories, setCategories] = useState([]);
  const [showAddCategoryModal, setShowAddCategoryModal] = useState(false);
  const [showEditCategoryModal, setShowEditCategoryModal] = useState(false);
  const [showRemoveCategoryModal, setShowRemoveCategoryModal] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState('');
  const store = getStoreSession();

  const handleShowAddCategoryModal = () => {
    setShowAddCategoryModal(!showAddCategoryModal);
  };

  const handleShowEditCategoryModal = () => {
    setShowEditCategoryModal(!showEditCategoryModal);
  };

  const handleShowRemoveCategoryModal = () => {
    setShowRemoveCategoryModal(!showRemoveCategoryModal);
  };

  const handleCategoryModal = (item, option) => {
    setSelectedCategory(item);
    if (option === 1) {
      handleShowAddCategoryModal();
    } else if (option === 2) {
      handleShowEditCategoryModal();
    } else {
      handleShowRemoveCategoryModal();
    }
  };

  const getStoreCategories = async (parentId, status) => {
    let foundCategories = [];
    try {
      const response = await storeMarketCategoryListByParent(store.id, parentId, status);
      if (response.success) {
        foundCategories = response.data;
      } else {
        foundCategories = [];
      }
    } catch (error) {
      foundCategories = [];
    }
    return foundCategories;
  };

  const getCategoryTreeFromParentView = async (parentId, status) => {
    const categoryArr = [];
    const existCategories = await getStoreCategories(parentId, status);
    await Promise.all(existCategories.map(async (eachCategory) => {
      const category = eachCategory;
      // eslint-disable-next-line no-await-in-loop
      const subCategory = await getCategoryTreeFromParentView(eachCategory.id, status);
      if (subCategory && subCategory !== '') {
        if (subCategory.length > 0) {
          category.children = subCategory;
        }
        category.text = eachCategory.name;
        category.title = eachCategory.name;
        category.href = '';
        category.icon = 'fas fa-home';
        category.target = '_top';
        categoryArr.push(category);
      }
    }));
    return categoryArr;
  };

  const getCategories = async () => {
    const categoriesData = await getCategoryTreeFromParentView(0, true);
    setCategories(categoriesData);
  };

  const renderItem = ({ item, collapseIcon }) => (
    <>
      <Row>
        <Col className="d-flex align-items-center justify-content-between">
          <div className="collApse">{collapseIcon} <Form.Text>{item.text}</Form.Text></div>
          <div className="pull-right">
            <Button onClick={() => handleCategoryModal(item, 1)}><i className="bi bi-plus"></i></Button>
            <Button onClick={() => handleCategoryModal(item, 2)}><i className="bi bi-pencil-square"></i></Button>
            {item.text !== 'Home' && item.text !== 'home' && <Button onClick={() => handleCategoryModal(item, 3)}><i className="bi bi-trash"></i></Button>}
          </div>
        </Col>
      </Row>
    </>
  );

  const handleConfirmChange = ({ dragItem }) => dragItem.text !== 'Home' && dragItem.text !== 'home';

  useEffect(() => {
    (async () => {
      await getCategories();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store.id]);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="categories minHeight">
        <div className="px-3 mx_0 m_0 mx_3 mx-5 pt-5">
          <h1 className="title mb-4">{title}</h1>
          <Card>
            <div className="p-3 border-bottom">
              <h3 className="fs-6 mb-1">Store Categories</h3>
              <p className="mb-0 text-muted">Arrange your store menu items and product categories by drag n drop them in preferred order.</p>
            </div>
            <div className="p-3">
              <Nestable
                items={categories}
                renderItem={renderItem}
                maxDepth={3}
                collapsed={false}
                confirmChange={handleConfirmChange}
              />
            </div>
          </Card>
        </div>
      </div>
      <ToastContainer />
      <AddCategoryModal
        storeId={store.id}
        selectedCategory={selectedCategory}
        getCategories={getCategories}
        showAddCategoryModal={showAddCategoryModal}
        handleShowAddCategoryModal={handleShowAddCategoryModal}
      />
      <EditCategoryModal
        selectedCategory={selectedCategory}
        getCategories={getCategories}
        showEditCategoryModal={showEditCategoryModal}
        handleShowEditCategoryModal={handleShowEditCategoryModal}
      />
      <RemoveCategoryModal
        selectedCategory={selectedCategory}
        getCategories={getCategories}
        showRemoveCategoryModal={showRemoveCategoryModal}
        handleShowRemoveCategoryModal={handleShowRemoveCategoryModal}
      />
    </>
  );
};

export default CategoryList;
