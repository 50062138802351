import { useEffect, useState } from 'react';
import {
  Badge, Button, Col, Form, InputGroup, Image, Table, Row,
} from 'react-bootstrap';
import Moment from 'react-moment';

import { getStoreSession } from '../../../services/StoreService';
import { productList } from '../../../services/ProductService';

const ProductList = ({ dragUrl }) => {
  const [products, setProducts] = useState([]);
  const store = getStoreSession();
  const getProducts = async () => {
    try {
      const response = await productList(store.id);
      if (response.success) {
        setProducts(response.data);
      } else {
        setProducts([]);
      }
    } catch (error) {
      setProducts([]);
    }
  };

  const getProductStatusBadge = (status) => {
    let badge = <Badge bg="success">Active</Badge>;
    if (status === 'active') {
      badge = <Badge bg="success">Active</Badge>;
    } else if (status === 'archived') {
      badge = <Badge bg="danger">Archived</Badge>;
    } else {
      badge = <Badge bg="info">Draft</Badge>;
    }
    return badge;
  };

  useEffect(() => {
    (async () => {
      await getProducts();
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [store]);

  return (
    <>
      <Row>
        <Col xs={12} md={6}>
          <div className="d-flex align-items-center mb_2 py-1 myProduct">
            <h6 className="text-white mb-0 me-2">Products</h6>
            <InputGroup>
              <Button variant="outline-secondary" id="button-addon1">
                <i className="bi bi-search"></i>
              </Button>
              <Form.Control
                placeholder="Search Products"
              />
            </InputGroup>
          </div>
        </Col>
        <Col xs={12} md={6}>
          <div className="py-1 pe-3">
            <InputGroup>
              <div className="position-relative">
                <Form.Select size="sm">
                  <option>Type</option>
                </Form.Select>
                <i className="bi bi-chevron-down"></i>
              </div>
              <div className="position-relative">
                <Form.Select size="sm">
                  <option>Satus</option>
                </Form.Select>
                <i className="bi bi-chevron-down"></i>
              </div>
              <div className="position-relative">
                <Form.Select size="sm">
                  <option>Vendor</option>
                </Form.Select>
                <i className="bi bi-chevron-down"></i>
              </div>
            </InputGroup>
          </div>
        </Col>
      </Row>
      <Row>
        <Col xs={12} className="productListTable">
          <Table striped bordered hover responsive variant="dark" size='sm'>
            <thead>
              <tr>
                <th>3D Model</th>
                <th>Product</th>
                <th>Type</th>
                <th>Status</th>
                <th>Vendor</th>
                <th>Last Synchronized</th>
              </tr>
            </thead>
            <tbody>
              {products && products.map((eachProduct) => (
                <tr key={eachProduct.id}>
                  <td>
                    <Image
                      src={eachProduct.Media?.[0]?.file_url}
                      thumbnail
                      width={50}
                      height={50}
                      alt="product"
                      draggable={true}
                      onDragStart={(e) => {
                        // eslint-disable-next-line
                        dragUrl.current = e.target.src;
                      }}
                    />
                  </td>
                  <td>{eachProduct.name}</td>
                  <td>{eachProduct.product_type}</td>
                  <td>{getProductStatusBadge(eachProduct.status)}</td>
                  <td>{eachProduct.vendor}</td>
                  <td><Moment format='DD-MM-YYYY HH:mm A'>{eachProduct.created_at}</Moment></td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Col>
      </Row>
    </>
  );
};

export default ProductList;
