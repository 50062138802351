import { useEffect, useState } from 'react';
import {
  Card,
  Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { currencyList } from '../../../services/StoreService';

const StoreCurrency = ({ store }) => {
  const [currencies, setCurrencies] = useState([]);

  const getCurrencies = async () => {
    try {
      const response = await currencyList();
      if (response.success) {
        setCurrencies(response.data);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  useEffect(() => {
    getCurrencies();
  }, []);

  return (
    <>
      <Card className="my-3">
        <div className="cardTitle">
          <h3 className="fs-6 mb-1">Store currency</h3>
          <p className="text-muted mb-0 text13">The currency your products are sold in.</p>
        </div>
        <div className="cardStore">
          <Form.Group className="mb-3 w-100">
            <Form.Label className="mb-0 text-muted text13">Store currency</Form.Label>
              <Form.Select defaultValue={store.store_currency} disabled readOnly>
                {currencies && currencies.map((currency) => (
                  <option
                    key={currency.id}
                    value={currency.currency_name}
                  >
                    {currency.currency_name}
                  </option>
                ))}
              </Form.Select>
          </Form.Group>
        </div>
      </Card>
    </>
  );
};

export default StoreCurrency;
