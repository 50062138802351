import { useEffect, useState } from 'react';
import { Button, Modal, Spinner } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { productAnchorDescriptionDelete } from '../../../services/ProductService';

const RemoveAnchorModal = ({
  selectedAnchor, getProduct, showRemoveAnchorModal, handleShowRemoveAnchorModal,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleRemoveAnchorModal = async () => {
    setIsLoading(true);
    try {
      const response = await productAnchorDescriptionDelete({ id: selectedAnchor.id });
      setIsLoading(false);
      if (response.success) {
        await getProduct();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    handleShowRemoveAnchorModal();
  };

  useEffect(() => {
    setShow(showRemoveAnchorModal);
  }, [showRemoveAnchorModal]);

  return (
    <Modal className="maxWidth" show={show} onHide={handleShowRemoveAnchorModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5 modal-title h4">Delete Anchor Description</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>This will delete selected Anchor Description. Are you sure?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" variant="secondary" onClick={handleShowRemoveAnchorModal}>
          No
        </Button>
        <Button variant="primary" onClick={handleRemoveAnchorModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Yes</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default RemoveAnchorModal;
