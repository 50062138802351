import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Button, Modal, Spinner, Form,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

import { getStoreSession, storeUpdate } from '../../../services/StoreService';

const schema = yup
  .object({
    vat_no: yup.string().label('VAT number')
      .required()
      .min(12)
      .max(12),
  })
  .required();

const AddVatNumber = ({
  showAddVatNumber,
  handleShowAddVatNumber,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddVatNumber = async (data) => {
    const store = getStoreSession();
    setIsLoading(true);
    try {
      const response = await storeUpdate({ store_id: store.id, vat_no: data.vat_no, is_vat_no: true });
      if (response.success) {
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
    setIsLoading(false);
    handleShowAddVatNumber();
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setShow(showAddVatNumber);
  }, [showAddVatNumber]);

  return (
    <>
      <Modal className="modalCard" show={show} onHide={handleShowAddVatNumber}>
        <Form onSubmit={handleSubmit(handleAddVatNumber)} noValidate>
          <Modal.Header closeButton>
            <Modal.Title className="fs-5">Add VAT number</Modal.Title>
          </Modal.Header>
          <Modal.Body>
          <p className="mb-0 text-muted">
            Your VAT number will be verified using the
            <Link to={'#'} className="text-decoration-none blueColor px-2" target='_blank' rel="noopener noreferrer">
            VAT Information Exchange System (VIES).<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
            This process can take a few days. We'll email you if there are any issues.
          </p>
          <Form.Group className="mb-3 mt-3">
            <Form.Label>VAT number</Form.Label>
            <Form.Control
              type="text"
              isInvalid={!!errors.vat_no}
              {...register('vat_no')}
            />
            <Form.Text>Enter 12 digit number</Form.Text>
            <Form.Control.Feedback type="invalid">{errors.vat_no?.message}</Form.Control.Feedback>
          </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button type="button" className="cancel" variant="primary" onClick={handleShowAddVatNumber}>
              Cancel
            </Button>
            <Button type="submit" variant="primary" disabled={isLoading}>
              <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
              <span className="px-2">Add VAT Number</span>
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </>
  );
};

export default AddVatNumber;
