import { useState } from 'react';
import { Image } from 'react-bootstrap';
import Folder from '../Explorer/Folder';
import File from '../Explorer/File';
import productIcon from '../../../assets/images/virtual/productIcon.png';
import appIcon from '../../../assets/images/virtual/appIcon.png';

const folderStructureData = [
  {
    id: 1,
    name: 'Content',
    isFolder: true,
    items: [
      {
        id: 11,
        name: 'Theme Collections',
        isFolder: true,
        items: [
          {
            id: 111,
            name: 'Display Stands',
            isFolder: true,
            items: [],
          },
          {
            id: 112,
            name: 'Other stuff',
            isFolder: true,
            items: [],
          },
        ],
      },
      {
        id: 12,
        name: 'Interior',
        isFolder: true,
        items: [
          {
            id: 121,
            name: 'Display Stands',
            isFolder: true,
            items: [],
          },
          {
            id: 122,
            name: 'Other stuff',
            isFolder: true,
            items: [],
          },
        ],
      },
      {
        id: 13,
        name: 'Marketing Content',
        isFolder: true,
        items: [
          {
            id: 131,
            name: 'Display Stands',
            isFolder: true,
            items: [],
          },
          {
            id: 132,
            name: 'Other stuff',
            isFolder: true,
            items: [],
          },
        ],
      },
      {
        id: 14,
        name: 'Props',
        isFolder: true,
        items: [
          {
            id: 141,
            name: 'Display Stands',
            isFolder: true,
            items: [],
          },
          {
            id: 142,
            name: 'Other stuff',
            isFolder: true,
            items: [],
          },
        ],
      },
    ],
  },
];

const MyAssetLibrary = ({ handleShow }) => {
  // eslint-disable-next-line no-unused-vars
  const [assets, setAssets] = useState(folderStructureData);
  const handleShowClick = (option, data = {}) => {
    handleShow(option, data);
  };

  return (
    <>
      <div className="fileExplorer">
        <h6 key="my_assets" className="text-white mb-0 assetsHeading">My Assets Library</h6>
        <div className="foldertree pt-3">
          {assets.map((item) => (item.isFolder ? <Folder key={`asset_${item.id}`} item={item} handleShow={handleShow} /> : <File key={item.id} item={item} />))}
          <p
            key="products"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(2)}
          >
            <Image src={productIcon} width={16} height={16} alt='product' /> Products
          </p>
          <p
            key="apps"
            style={{
              cursor: 'pointer',
            }}
            onClick={() => handleShowClick(3)}
          >
            <Image src={appIcon} width={16} height={16} alt='app' /> Apps
          </p>
        </div>
      </div>
    </>
  );
};

export default MyAssetLibrary;
