const keyStore = 'auth.store';

const setStoreSession = (store) => {
  localStorage.setItem(keyStore, JSON.stringify(store));
};

const getStoreSession = () => {
  const store = localStorage.getItem(keyStore);

  return JSON.parse(store);
};

const storeTypeList = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storetypelist`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const countryList = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/countrylist`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const currencyList = async () => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/currencylist`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeList = async (tenantId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storelist?tenant_id=${tenantId}`);
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const setStore = async (store) => new Promise((resolve, reject) => {
  try {
    if (store) {
      setStoreSession(store);
      resolve('Store set successfully.');
    } else {
      reject(new Error('Error occured. Please try again.'));
    }
  } catch (error) {
    reject(new Error(error.message));
  }
});

const storeDetail = async (storeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storedetail?id=${storeId}`);
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeUpdate = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/storeupdate`, {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeImageUpload = async (data, storeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/StoreImageUpload?store_id=${storeId}`, {
        method: 'POST',
        body: data,
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const storeVideoUpload = async (data, storeId) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/StoreVideoUpload?store_id=${storeId}`, {
        method: 'POST',
        body: data,
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(jsonResponse.message);
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

export {
  storeList, setStore, storeDetail, storeUpdate, storeImageUpload, storeVideoUpload, storeTypeList, countryList,
  currencyList, setStoreSession, getStoreSession,
};
