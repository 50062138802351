const keyUser = 'auth.user';
const keyStore = 'auth.store';

const setSession = (user, token) => {
  // Remove the password property.
  const { password, ...rest } = user;

  // Merge token to the final object.
  const merged = {
    ...rest,
    token,
  };
  localStorage.setItem(keyUser, JSON.stringify(merged));
};

const getSession = () => {
  const user = localStorage.getItem(keyUser);

  return JSON.parse(user);
};

const isAuth = () => !!getSession();

const login = async (email, password) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
          password,
        }),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        // eslint-disable-next-line
        // console.log(jsonResponse);
        if (jsonResponse.success) {
          setSession(jsonResponse.data.user, jsonResponse.data.token);
        }
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const logout = async () => new Promise((resolve) => {
  // Using setTimeout to simulate network latency.
  setTimeout(() => {
    localStorage.removeItem(keyUser);
    localStorage.removeItem(keyStore);
    resolve();
  }, 1000);
});

const sendForgotPassword = async (email) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/forgotpassword`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          email,
        }),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const sendResetPassword = async (data) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/resetpassword`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data),
      });
      const jsonResponse = await response.json();
      // eslint-disable-next-line
      // console.log(jsonResponse);
      if (response.ok && jsonResponse.success) {
        resolve(jsonResponse.message);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

const addUser = async (user) => new Promise((resolve, reject) => {
  (async () => {
    try {
      const { tcond, ...rest } = user;
      rest.role = 2; // eslint-disable-line no-param-reassign
      const response = await fetch(`${process.env.REACT_APP_API_FUNC_URL}/register`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(rest),
      });
      const jsonResponse = await response.json();
      if (response.ok) {
        // eslint-disable-next-line
        // console.log(jsonResponse.message);
        resolve(jsonResponse);
      } else {
        reject(new Error(jsonResponse.message));
      }
    } catch (error) {
      reject(new Error(error.message));
    }
  })();
});

// The useAuth hook is a wrapper to this service, make sure exported functions are also reflected
// in the useAuth hook.
export {
  getSession, isAuth, login, logout, sendForgotPassword, sendResetPassword, addUser,
};
