import { useEffect, useState } from 'react';
import {
  Button, Modal, Row, Col, Form, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { productAnchorDescriptionCreate } from '../../../services/ProductService';

const schema = yup
  .object({
    order_no: yup.string().label('Order number').required(),
    description: yup.string().label('Description').required(),
  })
  .required();

const AddAnchorModal = ({
  productId, getProduct, showAddAnchorModal, handleShowAddAnchorModal,
}) => {
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleAddAnchorModal = async (data) => {
    setIsLoading(true);
    try {
      const response = await productAnchorDescriptionCreate({
        product_id: productId,
        order_no: data.order_no,
        description: data.description,
      });
      setIsLoading(false);
      if (response.success) {
        handleShowAddAnchorModal();
        await getProduct();
        toast.success(response.message);
      } else {
        toast.error(response.message);
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    setShow(showAddAnchorModal);
  }, [showAddAnchorModal]);

  return (
    <Modal className="setDescription" show={show} onHide={handleShowAddAnchorModal}>
      <Form onSubmit={handleSubmit(handleAddAnchorModal)} noValidate>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Set Anchor Description</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Order Number</Form.Label>
                <Form.Control
                  type="text"
                  isInvalid={!!errors.order_no}
                  {...register('order_no')}
                />
                <Form.Control.Feedback type="invalid">{errors.order_no?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group className="mb-3">
                <Form.Label>Description</Form.Label>
                <Form.Control
                  as="textarea"
                  type="text"
                  rows={5}
                  isInvalid={!!errors.description}
                  {...register('description')}
                />
                <Form.Control.Feedback type="invalid">{errors.description?.message}</Form.Control.Feedback>
              </Form.Group>
            </Col>
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" type="button" variant="secondary" onClick={handleShowAddAnchorModal}>
            Close
          </Button>
          <Button type="submit" variant="primary" disabled={isLoading}>
            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
            <span className="px-2">Save</span>
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default AddAnchorModal;
