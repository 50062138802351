import { useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { chargeRemove } from '../../../services/BillingService';

const DeactivateSubscription = ({ chargeId, showDeactivateStore, handleShowDeactivateStore }) => {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();

  const handleDeactivateStore = async () => {
    if (!chargeId) {
      try {
        const response = await chargeRemove({ id: chargeId });
        if (response.success) {
          toast.success(response.message);
          navigate('/merchant');
        } else {
          toast.error(response.message);
        }
      } catch (error) {
        toast.error(error.message);
      }
    } else {
      toast.error('Some error occured. Please try again later.');
    }
    handleShowDeactivateStore();
  };

  useEffect(() => {
    setShow(showDeactivateStore);
  }, [showDeactivateStore]);

  return (
    <>
      <Modal show={show} onHide={handleShowDeactivateStore}>
        <Modal.Header closeButton>
          <Modal.Title className="fs-5">Deactivate Store</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          This will deactivate subscribed plan and move your VR Store unavailable for customers.
          Your store can be activated within 90 days. It will then be archived. Are you sure?
        </Modal.Body>
        <Modal.Footer>
          <Button className="cancel" variant="primary" onClick={handleShowDeactivateStore}>
            No
          </Button>
          <Button variant="primary" onClick={handleDeactivateStore}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeactivateSubscription;
