import { Helmet } from 'react-helmet';
import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  Button, Table, Dropdown, Badge,
} from 'react-bootstrap';
import Moment from 'react-moment';
import { ToastContainer } from 'react-toastify';

import './solutionlist.css';
import { getStoreSession } from '../../../services/StoreService';
import { solutionList } from '../../../services/SolutionService';
import SolutionCreate from '../../../components/virtual/solutionlist/SolutionCreate';
import SolutionSetting from '../../../components/virtual/solutionlist/SolutionSetting';
import SolutionDeleteModal from '../../../components/virtual/solutionlist/SolutionDeleteModal';
import SolutionPublishModal from '../../../components/virtual/solutionlist/SolutionPublishModal';

const SolutionList = () => {
  const title = 'Solutions';
  const [showSolutionCreate, setShowSolutionCreate] = useState(false);
  const [showSolutionPublish, setShowSolutionPublish] = useState(false);
  const [showSolutionSetting, setShowSolutionSetting] = useState(false);
  const [showSolutionDelete, setShowSolutionDelete] = useState(false);
  const [solutions, setSolutions] = useState([]);
  const [selectedSolution, setSelectedSolution] = useState({});
  const capitalizeFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);
  const navigate = useNavigate();

  const handleShowSolutionCreate = () => {
    setShowSolutionCreate(!showSolutionCreate);
  };

  const handleShowSolutionPublish = () => {
    setShowSolutionPublish(!showSolutionPublish);
  };

  const handleShowSolutionSetting = () => {
    setShowSolutionSetting(!showSolutionSetting);
  };

  const handleShowSolutionDelete = () => {
    setShowSolutionDelete(!showSolutionDelete);
  };

  const handleSelectedSolution = (solution, option) => {
    setSelectedSolution(solution);
    if (option === 1) { // Edit
      navigate(`/virtual/solution/edit/${solution.id}`);
    } else if (option === 2) { // Publish
      handleShowSolutionPublish();
    } else if (option === 3) { // Setting
      handleShowSolutionSetting();
    } else if (option === 4) { // Delete
      handleShowSolutionDelete();
    }
  };

  const getSolutions = async () => {
    const store = getStoreSession();
    try {
      const response = await solutionList(store.id);
      if (response.success) {
        setSolutions(response.data);
      } else {
        setSolutions([]);
      }
    } catch (error) {
      setSolutions([]);
    }
  };

  useEffect(() => {
    (async () => {
      await getSolutions();
    })();
  }, []);

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <div className="solutionList">
        <div className="add-new-btn">
          <Button variant="primary" onClick={handleShowSolutionCreate} className="btn btn-link p-0 bg-transparent border-0 text-decoration-none">
            <i className="bi bi-plus-lg me-2"></i>New
          </Button>
        </div>
        <div className="solution">
          <h1 className="fs-5 mb-3 text-white">{title}</h1>
          <div className="table-responsive">
            <Table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Actions</th>
                  <th>Status</th>
                  <th>Market</th>
                  <th>Created By</th>
                  <th>Created On</th>
                </tr>
              </thead>
              <tbody>
                {solutions && solutions.map((solution) => (
                  <tr key={solution.id}>
                    <th>
                      <Link to={`/virtual/solution/edit/${solution.id}`}>{solution.name}</Link>
                    </th>
                    <td>
                      <Dropdown>
                        <Dropdown.Toggle variant="success" id="dropdown-basic" className="p-0 bg-transparent border-0 text-black">
                          <i className="bi bi-three-dots"></i>
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item as="button" onClick={() => handleSelectedSolution(solution, 1)}>Edit</Dropdown.Item>
                          <Dropdown.Item as="button" onClick={() => handleSelectedSolution(solution, 2)}>Publish</Dropdown.Item>
                          <Dropdown.Item as="button" onClick={() => handleSelectedSolution(solution, 3)}>Settings</Dropdown.Item>
                          <Dropdown.Item as="button" onClick={() => handleSelectedSolution(solution, 4)}>Delete</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </td>
                    <td>
                      <Badge bg="success">{capitalizeFirstLetter(solution.status)}</Badge>
                    </td>
                    <td>{solution?.Market?.name}</td>
                    <td>{capitalizeFirstLetter(solution?.Tenant?.first_name)} {capitalizeFirstLetter(solution?.Tenant?.last_name)}</td>
                    <td><Moment format="DD-MM-YYYY HH:mm A">{solution.created_at}</Moment></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
      <ToastContainer />
      <SolutionCreate
        showSolutionCreate={showSolutionCreate}
        handleShowSolutionCreate={handleShowSolutionCreate}
        getSolutions={getSolutions}
      />
      <SolutionPublishModal
        showSolutionPublish={showSolutionPublish}
        handleShowSolutionPublish={handleShowSolutionPublish}
      />
      <SolutionSetting
        showSolutionSetting={showSolutionSetting}
        handleShowSolutionSetting={handleShowSolutionSetting}
        selectedSolution={selectedSolution}
      />
      <SolutionDeleteModal
        showSolutionDelete={showSolutionDelete}
        handleShowSolutionDelete={handleShowSolutionDelete}
        selectedSolution={selectedSolution}
        getSolutions={getSolutions}
      />
    </>
  );
};

export default SolutionList;
