import { useEffect, useState } from 'react';
import {
  Modal, Button, Row, Col, Spinner,
} from 'react-bootstrap';
import { toast } from 'react-toastify';

import { productStatusUpdate } from '../../../services/ProductService';

const MultiProductStatusModal = ({
  choosedStatus, selectedRows, showSatusModal, handleShowStatusModal, handleToggleCleared, getProducts,
}) => {
  const [show, setShow] = useState(false);
  const [statusAlertTitle, setStatusAlertTitle] = useState('');
  const [statusAlertStr, setSatusAlertStr] = useState('');
  const [totalCount, setTotalCount] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const handleStatusModal = async () => {
    setIsLoading(true);
    try {
      const response = await productStatusUpdate({ ids: selectedRows, product_status: choosedStatus });
      setIsLoading(false);
      if (response.success) {
        handleShowStatusModal();
        handleToggleCleared();
        toast.success(response.message);
        await getProducts();
      } else {
        handleShowStatusModal();
        toast.error(response.message);
      }
    } catch (error) {
      handleShowStatusModal();
      setIsLoading(false);
      toast.error(error.message);
    }
  };

  useEffect(() => {
    setShow(showSatusModal);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showSatusModal]);

  useEffect(() => {
    setTotalCount(selectedRows.length);
    if (Number(choosedStatus) === 1) {
      setStatusAlertTitle('Draft');
      setSatusAlertStr('Setting products as Draft will make them <strong>not visible</strong> to customers in VR Store.');
    } else if (Number(choosedStatus) === 2) {
      setStatusAlertTitle('Active');
      setSatusAlertStr('Setting products as Active will make them <strong>visible</strong> to customers in VR Store.');
    } else if (Number(choosedStatus) === 3) {
      setStatusAlertTitle('Archived');
      setSatusAlertStr('Setting products as Archived will <strong>stop synchronization</strong> and make them <strong>not visible</strong> to customers in VR Store.');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [choosedStatus]);

  return (
    <Modal className="width40 flexDirection" show={show} onHide={handleShowStatusModal}>
      <Modal.Header closeButton>
        <Modal.Title className="fs-5">Set {totalCount} product(s) as {statusAlertTitle}?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="col-md-12">
            <p className="mt-3" dangerouslySetInnerHTML={{ __html: statusAlertStr }}></p>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button className="cancel" type="button" variant="secondary" onClick={handleShowStatusModal}>
          Cancel
        </Button>
        <Button type="button" variant="primary" onClick={handleStatusModal} disabled={isLoading}>
          <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" hidden={!isLoading} />
          <span className="px-2">Set as {statusAlertTitle}</span>
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default MultiProductStatusModal;
