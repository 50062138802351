import { Image } from 'react-bootstrap';
import {
  chair, chair2, albamodernchair, productstandlarge, floorlamp,
} from '../../../../assets/images/virtual';

const File = ({ item }) => {
  const getFileImage = (fileName) => {
    let folderImg = <Image src={chair} width={16} height={16} alt='folder' />;
    if (fileName === 'Chair') {
      folderImg = <Image src={chair} width={16} height={16} alt='folder' />;
    } else if (fileName === 'Chair2') {
      folderImg = <Image src={chair2} width={16} height={16} alt='folder' />;
    } else if (fileName === 'Floor lamp') {
      folderImg = <Image src={floorlamp} width={16} height={16} alt='folder' />;
    } else if (fileName === 'Product stand large') {
      folderImg = <Image src={productstandlarge} width={16} height={16} alt='folder' />;
    } else if (fileName === 'Alba Modern Chair') {
      folderImg = <Image src={albamodernchair} width={16} height={16} alt='folder' />;
    }
    return folderImg;
  };

  return (
    <p
      key={item.id}
      style={{
        cursor: 'pointer',
      }}
    >
      {getFileImage(item.name)} {item.name}
    </p>
  );
};

export default File;
