import {
  Card,
  Row,
  Col,
  Form,
} from 'react-bootstrap';

const StandardFormats = ({ store }) => (
  <>
    <Card>
      <div className="cardTitle">
        <h3 className="fs-6 mb-1">Standards and formats</h3>
        <p className="text-muted mb-0 text13">Used for calculate product price, shipping weighs, and order times.</p>
      </div>
      <div className="cardStore">
        <Row>
          <Col xs={12}>
            <Form.Group className="mb-3 w-100">
              <Form.Label className="mb-0 text-muted text13">Store currency</Form.Label>
              <Form.Select disabled readOnly>
                <option value="">Time zone</option>
                <option value="(GMT +01:00) Stockholm">(GMT +01:00) Stockholm</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3 w-100">
              <Form.Label className="mb-0 text-muted text13">Unit system</Form.Label>
              <Form.Select disabled readOnly>
                <option value="Metric system">Metric system</option>
                <option value="Imperial system">Imperial system</option>
              </Form.Select>
            </Form.Group>
          </Col>
          <Col xs={6}>
            <Form.Group className="mb-3 w-100">
              <Form.Label className="mb-0 text-muted text13">Default weight unit</Form.Label>
              <Form.Control type="text" defaultValue={store.weight_unit} disabled readOnly/>
            </Form.Group>
          </Col>
        </Row>
      </div>
    </Card>
  </>
);

export default StandardFormats;
