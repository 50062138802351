import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';

const ImportProductOne = ({ handleImportProduct }) => (
  <>
    <div className="d-flex align-items-start">
      <div className="storeProducts w-100">
        <h3 className="text17 mb-2">Import your store products</h3>
        <p className="mb-5 text-muted">Import your products of choise from your connected online store. Click the "Get product list from online store" and select which products that should be available in your VR-store. After all selected products been imported, changes made in your online store product inventory will automatically be updated to you VCOM account.</p>
        <Button type="button" variant="primary" onClick={handleImportProduct}>Get product list from online store</Button>
        <div className="mt-4">
        <Link className="infoColor text-decoration-none" to={'https://vcomverse.io/docs/import-products/'} target="_blank" rel="noopener noreferrer"><i className="bi bi-question-circle-fill me-1"></i>Learn more about import and syncronizing products<i className="bi bi-box-arrow-up-right ms-1"></i></Link>
        </div>
      </div>
      <div className="col-md-3"></div>
    </div>
  </>
);

export default ImportProductOne;
